import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from "firebase";
import QRCode from "qrcode.react";
import JSZip from "jszip";
import FileSaver from 'file-saver';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinkIcon from '@material-ui/icons/Link';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import ReactExport from 'react-data-export';
import CircularProgress from '@material-ui/core/CircularProgress';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Serial Number' },
  { id: 'picked', numeric: true, disablePadding: false, label: 'Picked Date' },
  { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
  { id: 'award', numeric: true, disablePadding: false, label: 'Award' },
  { id: 'lucky_number', numeric: true, disablePadding: false, label: 'Lucky Number' },
];

const headMobileCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Serial Number' },
  { id: 'info', numeric: true, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, matches} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  

  return (
    <TableHead>
      
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
      </TableCell>
        {!matches ? 
         headMobileCells.map((headCell) => (
          <TableCell
          style={{padding: 5}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )):
        headCells.map((headCell) => (
          <TableCell
          style={{padding: 5}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onclickAddVoucher } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
         
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton disabled aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add Voucher">
          <IconButton disabled aria-label="add-voucher" onClick={onclickAddVoucher}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    minWidth: 250,
  },
}));

export default function EnhancedTable() {

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('picked');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState("");
  const [searchValue, setSearchValue] = React.useState(0);
  const [searchtxt, setSearchtxt] = React.useState("");
  const [selectid, setSelectid] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [total, setTotal] = React.useState(1);
  var zip = new JSZip();
  const [multiDataSet, setMultiDataSet] = React.useState([])
  var count = 0;

  const [folder, setFolder] = React.useState('-N5pIXRGsDWHmQH5jJRu');
  const [listFolder, setListFolder] = React.useState([]);

  useEffect(() => {

    let folderRef = firebase.database().ref('customer_form_folder');

    folderRef.once('value', snapshot => {
      if(snapshot.exists()){
        var listFolder = [];
        snapshot.forEach(child => {

            var folder_value = child.val();
            folder_value.id = child.key
            listFolder.push(folder_value)
        })

        setListFolder(listFolder)
        loadVape(folder)
      }
    })

   

  }, []);

  const loadVape = (folderId) => {
    let vapeRef = firebase.database().ref('lucky_picked2/' + folderId);

    vapeRef.on('value', snapshot => {
     
      let vapes = [];
      var listvapes = [{
        columns: [
            {title: "", width: {wpx: 60}},//pixels width 
            {title: "", width: {wpx: 150}},//pixels width 
            {title: "", width: {wpx: 70}},//char width
            {title: "", width: {wpx: 150}},//char width
            {title: "", width: {wpx: 150}},//char width
            {title: "", width: {wpx: 150}},//char width
            {title: "", width: {wpx: 200}},//char width
            {title: "", width: {wpx: 300}},//char width
            {title: "", width: {wpx: 400}},//char width
        ],
        data: [
            [
              {value: "No.", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Serial Number", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Collected", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Date Collected", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Shop name", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Phone", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Email", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Selling Platform", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "QR Code", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
            ]
        ]
      }];
      var no = 1;
      snapshot.forEach(child => {
       
        var vapes_value = child.val();
        vapes_value.collected_date = child.val().collected_date ? child.val().collected_date : "";
        vapes_value.name = child.val().name ? child.val().name : "";
        vapes_value.email = child.val().email ? child.val().email : "";
        vapes_value.phone = child.val().phone ? child.val().phone : "";
        vapes_value.other_content = child.val().other_content ? child.val().other_content : "";
        vapes_value.other = child.val().other ? child.val().other : "";
        vapes.push(vapes_value);

        var sellingPlatform = [];
        if(vapes_value.lazada){
          sellingPlatform.push("Lazada")
        }
        
        if(vapes_value.shopee){
          sellingPlatform.push("Shopee")
        }
        
        if(vapes_value.seller){
          sellingPlatform.push("Social Media Seller")
        }
        
        if(vapes_value.store){
          sellingPlatform.push("Physical Store")
        }
        
        if(vapes_value.other){

          if(vapes_value.other_content){
            sellingPlatform.push("Other[" + vapes_value.other_content + "]")
          }
          else
          {
            sellingPlatform.push("Other")
          }
         
        }
        var sellingPlatformString = sellingPlatform.join(", ");

        var value_data = [
          {value: no++, style: { alignment: { horizontal: "left" }, font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.id, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.collected_date ? "Yes": "No" , style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.collected_date ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(vapes_value.collected_date) : "", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.name ?  vapes_value.name : "", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.phone ? vapes_value.phone:"", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: vapes_value.email ? vapes_value.email : "", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: sellingPlatform.length > 0 ? sellingPlatformString : "", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          {value: "https://authorize.sp2smalaysia.com/voucher/" +  vapes_value.id, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
        ]
        listvapes[0].data.push(value_data)
        
      })
      setMultiDataSet(listvapes)
      setData(vapes)
      setRows(vapes)
      setLoading(false)
    })
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  const putintoZip = () => {
    const canvas = document.getElementById(rows[count].id);
    canvas.toBlob(function (blob) {
      zip.file(rows[count].id + ".png", blob);

      if(count < 700-1){
        count++;
        putintoZip();
      }
      else
      {
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          FileSaver.saveAs(content, "Sp2sVoucher700Vouchers.zip");
        });
      }
      
    });
  }

  const downloadAllQR = () => {  
    putintoZip();
  };

  const onclickReset = () => {
    setOpen2(true);
  }

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  
  const onInfoClick = (info) => {
    setInfo(info);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const search = event => {

    if(searchValue == 0){
      var filterData = data.filter(item => item.id ? item.id.includes(event.target.value): "");

      
      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    else if(searchValue == 1){
      var filterData = data.filter(item => item.lucky_number ? item.lucky_number.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    
    setSearchtxt(event.target.value)
    setPage(0)
  }

  const onChangeSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
    setRows(data)
  }

  const confirmReset = () => {

    firebase.database().ref('lucky_picked2/' + folder).remove();
    setOpen2(false);
  }

  const openLink = (id) => {
    window.open(window.location.href.slice(0, -8) + "voucher/" + id, "_blank")
  }

  const onclickAddVoucher = () => {
    setTotal(1)
    setOpen5(true);
  }

  const changeTotal = (event) => {
    setTotal(event.target.value)
}
const handleFolderChange = (event) => {
  setFolder(event.target.value);
  loadVape(event.target.value)
};

  const renderAward = (award_text) => {
    if(award_text === "consolation"){
      return "Consolation Prize : SP2S II Device"
    }
    else if(award_text === "champion"){
      return "1st Prize RM10,000"
    }
    else if(award_text === "runnerup"){
      return "2nd Prize Rm3,888"
    }
    else if(award_text === "thirdplace"){
      return "3rd Prize RM2,888"
    }
  }

  const writeData = () => {

    if(total > 0){

        var selectedvoucher = [];

        for(var i = 0; i < total; i++) {
          selectedvoucher.push(i.toString());
        }
        
        setLoadingCount(0)

        var time = 10;
        selectedvoucher.forEach((child, index) => {
            setTimeout(function() {
                
                var number = (Math.random()+' ').substring(2,10)+(Math.random()+' ').substring(2,10);

                console.log(number)
                var postData = {
                    id: number,
                    created: firebase.database.ServerValue.TIMESTAMP,
                    collected: false
                };
                var updates = {};
                updates['/voucher/'+number] = postData;

                firebase.database().ref().update(updates);
                
                setLoadingCount(index)
                
                if(index == total -1){
                  setOpen5(false);
                }
            }, time);
            time += 10;
        })
    }
    
  }

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  var d = new Date();
    var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + "-" + 
    d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

    if (loading) {
      return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center',marginTop: 30  }}>
        <CircularProgress color="primary" /></div>;
    }

  return (
    <div className={classes.root}>
      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Voucher"}</DialogTitle>
        <DialogContent>
          <Grid style={{ width: 300 }}>
              <Grid>
                  <TextField
                      id="outlined-number"
                      label="Number"
                      type="number"
                      value={total}
                      style={{ width: "100%"}}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      inputProps={{ min: 1 }}
                      variant="outlined"
                      onChange={changeTotal}
                      />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} color="primary">
            Cancel
          </Button>
          <Button onClick={writeData} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to reset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            cancel
          </Button>
          <Button onClick={confirmReset} color="primary" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Info
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 300 }}>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Serial Number 
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.id}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Date Collected
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.collected_date ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(info.collected_date):"-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Shop Name
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.name ? info.name : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Phone number
              </Typography>
              <Typography variant="body2"  style={{ marginTop: -5 }} gutterBottom>
                {info.phone ? info.phone : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Email
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.email ? info.email : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: -5}} gutterBottom>
                Selling Platform
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" gutterBottom>
                {info.lazada ? "Lazada ": ""}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2"  gutterBottom>
                {info.shopee ? "Shopee ": ""}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2"  gutterBottom>
                {info.seller ? "Social Media Seller ": ""}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2"  gutterBottom>
                {info.store ? "Physical Store ": ""}
              </Typography>
            </Grid>
            {info.other != "" ? <Grid style={{ display:'flex', flexDirection:'row'}}>
              <Typography variant="body2"  gutterBottom>
                {info.other ? "Others ": ""}
              </Typography>
              <Typography variant="body2" style={{ marginLeft: 10}}  gutterBottom>
                {" ["+info.other_content+"]"}
              </Typography>
            </Grid>: null}
        
        </DialogContent>
      </Dialog>
      <Paper className={classes.paper}>
        {/*<Grid container style={{ display:'flex',}}>
          <Grid item xs={6}>
          <Tooltip title="Download Vape List">
            <ExcelFile element={
            <Button style={{ width:"100%", whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" startIcon={<GetAppIcon />} color="primary" disableElevation>
            Lucky Draw List
            </Button>}  filename={"SP2s_Vouchers_" + datestring}>
                <ExcelSheet dataSet={multiDataSet} name="Vapes"/>
            </ExcelFile>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Download All QR code">
              <Button disabled onClick={downloadAllQR}  style={{ width:"100%", backgroundColor:"#061a26", whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" startIcon={<GetAppIcon />} color="primary" disableElevation>
             All QR code
              </Button>
            </Tooltip>
          </Grid>
         
        </Grid>*/}
        <Grid container style={{ display:'flex',}}>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Select Folder</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={folder}
                onChange={handleFolderChange}
                label="Select Folder"
                >
                {listFolder.map(val => {
                    return(<MenuItem value={val.id}>
                    <em>{val.name}</em>
                </MenuItem>)
                })}
                </Select>
            </FormControl>
            </Grid>
          <Grid item xs={6}>
          <Tooltip title="Reset">
              <Button onClick={onclickReset}  style={{ width:"100%", backgroundColor:"#061a26", whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" color="primary" disableElevation>
             Reset
              </Button>
            </Tooltip>
            </Grid>
        </Grid>
        <EnhancedTableToolbar numSelected={selected.length} onclickAddVoucher={onclickAddVoucher}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              matches={matches}
            />
            <TableCell padding={'none'}  colSpan={7}>
              
              <Grid container>
                <Grid item md={10} xs={12}>
                <FormControl style={{ marginTop:10 }}  fullWidth >
                    <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                    <Input onChange={(e) => search(e)}
                      value={searchtxt}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment style={{ marginLeft:20 }} position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={12} style={{marginTop:10, paddingLeft: 15}}>
                  <FormControl style={{ width: "100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">Search by</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={searchValue}
                        label="Search by"
                        onChange={(e) => onChangeSelect(e)}
                      >
                        <MenuItem value={0}>Serial Number</MenuItem>
                        <MenuItem value={1}>Lucky Number</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>
              </Grid>
                
            </TableCell>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" style={{padding: 8}}>
                      {row.id}
                      </TableCell>
                       {!matches ? null : <TableCell style={{padding: 5}}align="left">
                         {row.picked ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(row.picked):"-"}
                       </TableCell>}
                       {!matches ? null :<TableCell style={{padding: 5}}align="left">
                      {row.last_name + " " + row.name}
                      </TableCell>}
                      {!matches ? null :<TableCell style={{padding: 5}}align="left">
                      {row.award}
                      </TableCell>}
                      {!matches ? null :<TableCell style={{padding: 5}}align="left">
                      {row.lucky_number}
                      </TableCell>}
                      {/*<TableCell style={{padding: 5}} align="left">
                        <Tooltip title="Info">
                          <IconButton onClick={() => onInfoClick(row) }>
                              <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download QRcode">
                          <IconButton onClick={() => downloadQR(row.id) }>
                              <GetAppIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset">
                          <IconButton onClick={() => onclickReset(row.id) }>
                              <ReplayIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Link">
                          <IconButton onClick={() => openLink(row.id) }>
                              <LinkIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        
                  </TableCell>*/}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          labelRowsPerPage={"per page"}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}