import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect  } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

//authentication
import Login from "./screens/authentication/Login";
import Voucher from "./screens/Voucher";
import Warrantly from "./screens/Warrantly";
import CheckWarrantly from "./screens/CheckWarranty";
import VerifyProduct from "./screens/VerifyProduct";
import Vouchers from "./screens/Vouchers";
import InvalidVoucher from "./screens/InvalidVoucher";
import Navigation from "./screens/Navigation";
import Vape from "./screens/Vape";
import Customer from "./screens/Customer";
import ProductExchange from "./screens/ProductExchange";
import LuckyDraw from "./screens/LuckyDraw";
import LuckyDraw2 from "./screens/LuckyDraw2";
import QRScanner from "./screens/QRScanner";
import CustomerForm from "./screens/CustomerForm";
import ItemExchange from "./screens/ItemExchange";
import StoreUser from "./screens/Store_user";
import StoreUserLogin from "./screens/authentication/StoreLogin";
import app from "./firebase/base";

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#D9B2A9',
      main: '#A6695B',
      dark: '#734949',
    },
    secondary: {
      light: '#c90808',
      main: '#a60000',
      dark: '#930000',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#061A26',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

class App extends Component {

  state = {
    loading: true, 
    authenticated: false, 
    currentUser: null
  };

  componentWillMount() {

    app.auth().onAuthStateChanged(user => {
      
      if (user) {

        this.setState({
          authenticated: true,
          loading: false,
          currentUser: user.email
        });
        
      } else {

        this.setState({
          authenticated: false,
          loading: false,
          currentUser: null
        });

      }
    });

    

    
    /*fetch('http://sp2smalaysia.com/wp-json/wc/v3/products?per_page=100', {
        mode: 'no-cors' // 'cors' by default
      })
      .then(function(response) {
        // Do something with response

        console.log(response)
      });*/

  }

  renderRoute(){
    return(
      <Switch>
        <Route path="/warrantly/:id" component={Warrantly} />
        <Route path="/form" component={CustomerForm} />
        <Route path="/exchange" component={ItemExchange} />
        <Route path="/claimwarranty" component={CheckWarrantly} />
        <Route path="/verify" component={VerifyProduct} />
        <Route path="/voucher/:id" component={Voucher} />
        <Route path="/voucher" component={InvalidVoucher} />
        <Route path="/vouchers" component={Vouchers}/>
        <Route path="/vape" component={Vape} exact/>
        <Route path="/customer" component={Customer}/>
        <Route path="/customer_exchange" component={ProductExchange}/>
        <Route path="/store_user" component={StoreUser}/>
        <Route path="/store_admin_panel" component={StoreUserLogin}/>
        <Route path="/luckydraw" component={LuckyDraw}/>
        <Route path="/luckydraw2" component={LuckyDraw2}/>
        <Route path="/qrscanner" component={QRScanner}/>
        <Route path="/" component={Vape} />
      </Switch>
    )
  }

  render(){

    const { authenticated, loading } = this.state;

    if (loading) {
      return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
    }

    if(authenticated)
    {

      if(this.state.currentUser === "luckydraw@sp2smalaysia.com"){
        return(
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Switch>
              <Route path="/luckydraw" component={LuckyDraw}/>
              <Route path="/" render={() => (<Redirect to="/luckydraw" />)} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        )
      }
      return(
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Navigation components={this.renderRoute()}/>
          </BrowserRouter>
        </ThemeProvider>
      )
    }
    else
    {
      return(
        <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/warrantly/:id" component={Warrantly} />
            <Route path="/form" component={CustomerForm} />
            <Route path="/exchange" component={ItemExchange} />
            <Route path="/claimwarranty" component={CheckWarrantly} />
            <Route path="/verify" component={VerifyProduct} />
            <Route path="/voucher/:id" component={Voucher} />
            <Route path="/voucher" component={InvalidVoucher} />
            <Route path="/login" component={Login} />
            <Route path="/store_admin_panel" component={StoreUserLogin}/>
            <Route path="/qrscanner" component={QRScanner}/>
            <Route path="/" render={() => (<Redirect to="/login" />)} />
          </Switch>
        </BrowserRouter>
        </ThemeProvider>
      )
    }
    
  }

}

export default App;
