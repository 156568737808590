import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import MuiPhoneInput from 'material-ui-phone-number';
import Divider from '@material-ui/core/Divider';
import EmailValidator from 'email-validator';
import QrReader from 'react-qr-reader'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
  

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%",
    backgroundColor: "#1a1a1a",
    color: "white"
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [vapeid, setVapeid] = React.useState("");
  const [registeredDate, setRegisteredDate] = React.useState(null);
  const [claim, setClaim] = React.useState(null);
  const [claimDate, setClaimDate] = React.useState(null);
  const [claimNoted, setClaimNoted] = React.useState("");
  const [otherissue, setOtherissue] = React.useState("");
  const [otherNoted, setOtherNoted] = React.useState("");
  const [isvalid, setIsvalid] = React.useState(true);
  const [isregistered, setIsregistered] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [scannnerqrcode, setScannnerqrcode] = React.useState(false);
  const [issue, setIssue] = React.useState("");
  const [dealerID, setDealerID] = React.useState("");
  const [dealerId, setDealerId] = React.useState("");
  const [dealerIDerror, setDealerIDerror] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });

  const [chargingIssue, setChargingIssue] = React.useState(null);
  const [powerIssue, setPowerIssue] = React.useState(null);
  const [PODIssue, setPODIssue] = React.useState(null);
  const [otherIssue, setOtherIssue] = React.useState(null);

  const theme = createMuiTheme({
    palette: {
        primary: {
          light: '#99d700',
          main: '#99d700',
          dark: '#99d700',
        },
        secondary: {
          light: '#c90808',
          main: '#a60000',
          dark: '#930000',
        },
      type: 'dark',
    },
    
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  })

  const handleClose = () => {
    setOpen(false);
  };

  const hancleQRClick = (event) => {
    setScannnerqrcode(true)
  }

  const hancleClick = (event) => {
    setLoading(true)
    checkVapeID(vapeid)
    
  }

  const handleChecBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const checkVapeID = (vape_id) => {
    if(vape_id.replaceAll(/\s/g,'').length == 16){
      let vapeRef = firebase.database().ref('vape/'+vape_id.replaceAll(/\s/g,''));

      vapeRef.on('value', snapshot => {
       
        if(snapshot.exists()){

          if(snapshot.val().registered_date){
            setRegisteredDate(snapshot.val().registered_date)
            setClaim(snapshot.val().claim)
            setClaimDate(snapshot.val().claim_date)
            setClaimNoted(snapshot.val().claim_noted)
            setOtherissue(snapshot.val().others_noted)
            setChargingIssue(snapshot.val().charging_issue)
            setPowerIssue(snapshot.val().power_issue)
            setPODIssue(snapshot.val().pod_not_detected)
            setOtherIssue(snapshot.val().others)
            setDealerId(snapshot.val().dealer_id)
            setIsregistered(true)
            setIsvalid(true)
          }
          else
          {
            setIsregistered(false)
            setIsvalid(false)
          }
          
          
        }
        else
        {
           setIsregistered(true)
            setIsvalid(false)
          
        }

        setLoading(false)

      })
    }
    else
    {
      setIsvalid(false)
      setLoading(false)

    }
  }

  const handleYes = (event) => {

    if(dealerID !== ""){
      let vapeRef = firebase.database().ref('vape/'+vapeid.replaceAll(/\s/g,''));
      vapeRef.update({ claim: true, 
        claim_date: firebase.database.ServerValue.TIMESTAMP, 
        charging_issue: state.checkedA,
        power_issue: state.checkedB,
        pod_not_detected: state.checkedC,
        others: state.checkedD,
        others_noted: issue,
        dealer_id: dealerID,
        claim_noted: otherNoted })

        setOpen(false)
    }
    else{
      setDealerIDerror(true)
    }
    

    
  }

  const handleVapeIDChange = (event) => {
    setVapeid(event.target.value)
  }

  const hancleClaimClick = (event) => {
    setOpen(true)
  }

  const handleIssueChange = (event) => {
    setIssue(event.target.value)
  }

  const handleDealerIDChange = (event) => {
    setDealerID(event.target.value)
  }

  const handleNoteChange = (event) => {
    setOtherNoted(event.target.value)
  }

  const hancleBackClick = (event) => {
    setRegisteredDate(null)
  }

  const handleScan = (data) => {
    if(data){
      setScannnerqrcode(false)
      setVapeid(data)
      checkVapeID(data)
    }
  }

  const handleError = (err) =>{
    console.error(err)
  }

  const handleBackClick = () => {
    setScannnerqrcode(false)
  }

  if(scannnerqrcode){
    return(
      <ThemeProvider theme={theme}>
      <Grid>

        <QrReader
            style={{ maxWidth: 600 }}
            delay={50}
            onError={handleError}
            onScan={handleScan}
            />

        <Button onClick={handleBackClick} style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20, width: "100%" }} variant="contained" disableElevation color="primary">Back</Button>
      </Grid>
      </ThemeProvider>
    )
  }

  if(registeredDate){

    const moment = require('moment');

    return(
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
          <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warranty Claim Form"}</DialogTitle>
                <DialogContent>
                  <Grid style={{ width: "100%" }} md={12} item xs={12}>

                  <form noValidate autoComplete="off">
                      <TextField
                          style={{ marginBottom: 10 }}
                          id="dealerID"
                          value={dealerID}
                          onChange={handleDealerIDChange}
                          label="Dealer ID"
                          variant="outlined"
                          fullWidth
                          error={dealerIDerror}
                        />
                    </form>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedA}
                            onChange={handleChecBoxChange}
                            name="checkedA"
                            color="primary"
                          />
                        }
                        label="Charging Issues"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedB}
                            onChange={handleChecBoxChange}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Power Issues"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedC}
                            onChange={handleChecBoxChange}
                            name="checkedC"
                            color="primary"
                          />
                        }
                        label="PODs Can't be detected"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedD}
                            onChange={handleChecBoxChange}
                            name="checkedD"
                            color="primary"
                          />
                        }
                        label="Other Issues"
                      />

                    {state.checkedD ? <form noValidate autoComplete="off">
                      <TextField
                          style={{ marginTop: 10 }}
                          id="issue"
                          value={issue}
                          onChange={handleIssueChange}
                          label="Other Issues"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={6}
                        />
                    </form> : null}

                    <form noValidate autoComplete="off">
                      <TextField
                          style={{ marginTop: 10 }}
                          id="issue"
                          value={otherNoted}
                          onChange={handleNoteChange}
                          label="Note"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={6}
                        />
                    </form>
                  </Grid>

                </DialogContent>
                <DialogActions>
                <Button style={{ color: 'gray', fontWeight: 'bold'}} onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button style={{ fontWeight: 'bold'}} onClick={handleYes} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
          <Grid style={{ backgroundColor: "#1a1a1a", color: "white", height: "100%" }}>
            <Grid style={{ marginTop: 20, display:'flex', justifyContent: "center" }}>
              <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
            </Grid>
            <Divider style={{ opacity:.3 }}/>
            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Dealer ID</Typography>
            <Typography style={{ textAlign:"center" }} variant="body1">{dealerId ? dealerId : "-"}</Typography>

            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Warranty</Typography>
            <Typography style={{ textAlign:"center" }} variant="body1">{moment(registeredDate).add(12, 'M').format('DD-MM-YYYY')}</Typography>

            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Claimed</Typography>
            <Typography style={{ textAlign:"center" }} variant="body1">{claim ? "Yes": "No"}</Typography>

            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Claimed Date</Typography>
            <Typography style={{ textAlign:"center" }} variant="body1">{claimDate ? moment(claimDate).format('DD-MM-YYYY HH:mm'): "-"}</Typography>

            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Issues</Typography>
            {chargingIssue ? <Typography style={{ textAlign:"center" }} variant="body1">{"Charging Issues"}</Typography>: null }
            {powerIssue ? <Typography style={{ textAlign:"center" }} variant="body1">{"Power Issues"}</Typography> : null }
            {PODIssue ? <Typography style={{ textAlign:"center" }} variant="body1">{"PODs Can't be detected"}</Typography> : null }
            {otherIssue ? <Typography style={{ textAlign:"center" }} variant="body1">{"Other Issues"}</Typography> : null}
            {otherIssue ? <Typography style={{ textAlign:"center", opacity: .4 }} variant="body2">{otherissue ? otherissue: "-"}</Typography> : null}
            <Typography style={{ textAlign:"center" }} variant="body1">{claim ? "": "-"}</Typography>

            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Note</Typography>
            <Typography style={{ textAlign:"center", opacity: .4 }} variant="body2">{claimNoted ? claimNoted: "-"}</Typography>

            <Button disabled={claim} onClick={hancleClaimClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Claim Now</Button>
            <Button onClick={hancleBackClick} style={{ fontWeight: 'bold', fontSize: 14, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Back</Button>
          </Grid>
          </div>
        </ThemeProvider>
    )
    
  }
  
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid style={{ padding: 20 }}>

              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Check Warranty</Typography>
              
              <Grid style={{ maxWidth: 500, width: "100%", marginTop: 15 }}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                  <Grid md={12} item xs={12}>
                  <form noValidate autoComplete="off">
                    <TextField
                        error={isvalid ?false: true}
                        id="vapeid"
                        value={vapeid}
                        onChange={handleVapeIDChange}
                        label="Insert Your Vape ID"
                        variant="outlined"
                        fullWidth
                        
                        inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                        helperText={isvalid ? "":!isregistered ? "Your vape haven't register warranty yet": "Please insert your valid vape ID"}
                      />
                      </form>
                  </Grid>
                  
                </Grid>

                <Button onClick={hancleClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Submit</Button>

                <Button onClick={hancleQRClick} style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20, width: "100%" }} variant="contained" disableElevation color="primary">Scan QR Code</Button>
              </Grid>
            </Grid>
            </MuiPickersUtilsProvider>
        </div>
        </ThemeProvider>
      );
  

  
}