import React, { Component } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import app from "../../firebase/base";
import ExchangeList from "../Customer_exchange_list_store";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      SP2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    height: '100vh',
    
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/sp2s-luckydraw.appspot.com/o/Login%20image%2Flogin.jpg?alt=media&token=0a56c619-35dc-45d6-a3ae-b05f64f23bcf)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  recaptcha: {
    marginTop: theme.spacing(1),
  }
});

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.captcha = null;
        this.state = {
          isVerified: true,
          email: '',
          password: '',
          isChecked: false,
          login: false,
          open: false,
          newPassword: "",
          newConfirmPassword: "",
          oldPassword: "",
          currentID: "",
          redeemTime: 0,
          redeemDate: null
        }
    }

    onVerify = async () => {
        this.setState({
          isVerified: true
        });
    };
    
    onExpire = () => {
        this.setState({
            isVerified: false
        });
    };

    handleSignIn = async event => {

        event.preventDefault();
    
        if (this.state.isVerified) {
          const { email, password, isChecked } = this.state;

          
          if (isChecked && email !== "") {
            localStorage.username = email
            localStorage.password = password
            localStorage.checkbox = isChecked
          }
          else
          {
            localStorage.username = ""
            localStorage.password = ""
            localStorage.checkbox = false
          }

          let userRef = app.database().ref('store_user');
          userRef.once('value', snapshot => {
            if(snapshot.exists()){
              var correct = false;
              var currentID = ""
              var redeemTime = 0;
              var redeemDate = null;
              snapshot.forEach((child) => {
                if(child.val().password === password && child.val().store_id === email){
                  correct = true;
                  currentID = child.key
                  redeemTime= child.val().redeem_time
                  redeemDate =  child.val().redeem_date
                }
              })
              
              if(correct === true){
                this.setState({
                  login: true, currentID, redeemTime, redeemDate
                });
              }
              else{
                alert('Please enter correct Store Id & Password.');
              }
            }
          })
          /*try {
            await app
              .auth()
              .signInWithEmailAndPassword(email, password);
              
          } catch (error) {
            alert(error);
          }*/

        
        } else {
          alert('Please verify that you are a human!');
        }
        
    };

    handleChange = event => {
        this.setState({ email: event.target.value });
    };

    passwordhandleChange = event => {
        this.setState({ password: event.target.value });
    };

    componentDidMount() {
       
        if (localStorage.checkbox && localStorage.username !== "") {
            
            this.setState({
                isChecked: true,
                email: localStorage.username,
                password: localStorage.password
            })
        }
    }

    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    handleDialogClose = () => {
      this.setState({
        open: false
      })
    };

    handleOldPassword = event => {
      this.setState({
        oldPassword: event.target.value
      })
    }

    handleNewPassword = event => {
      this.setState({
        newPassword: event.target.value
      })
    }

    handleNewConfirmPassword = event => {
      this.setState({
        newConfirmPassword: event.target.value
      })
    }

    handleSubmitResetPassword = () => {
      if(this.state.oldPassword !== this.state.password){
        alert('Old password incorrect!');
      }
      else if(this.state.oldPassword === this.state.newPassword){
        alert('New password and old password cannot be same!');
      }
      else if(this.state.newPassword.length < 7){
        alert('Password must be more than 7 digits');
      }
      else if(this.state.newPassword !== this.state.newConfirmPassword){
        alert('Please confirm your new password');
      }
      else if(this.state.oldPassword === ""){
        alert('Old password Empty!');
      }
      else if(this.state.newPassword === ""){
        alert('New password Empty!');
      }
      else if(this.state.newConfirmPassword === ""){
        alert('Confirm password Empty!');
      }
      else{
        app.database().ref('store_user').child(this.state.currentID).update({ password: this.state.newPassword }).then(()=>{
          this.setState({ login: false, password: "", oldPassword: "", newPassword: "", newConfirmPassword: "", open: false })
        })
      }
      
    }

    render() {

        const { classes } = this.props;

        if(this.state.login){
          return (<Grid style={{ height: "100vh", backgroundColor: "#061a26"}} >
            <Dialog open={this.state.open} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please change new password here
                </DialogContentText>
                <TextField
                  autoFocus
                  value={this.state.oldPassword}
                  onChange={this.handleOldPassword}
                  margin="dense"
                  id="oldpassword"
                  label="Old Password"
                  type="password"
                  fullWidth
                />
                <TextField
                  value={this.state.newPassword}
                  onChange={this.handleNewPassword}
                  margin="dense"
                  id="newpassword"
                  label="New Password"
                  type="password"
                  fullWidth
                />
                <TextField
                  value={this.state.newConfirmPassword}
                  onChange={this.handleNewConfirmPassword}
                  margin="dense"
                  id="confirmpassword"
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSubmitResetPassword} color="primary">
                  Change
                </Button>
              </DialogActions>
            </Dialog>
            <Grid style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
              <Grid>
                <Typography style={{ color: "white", marginRight: 10 }} variant="caption">
                    {this.state.redeemDate ? "(Last Redeem: " + new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.state.redeemDate) +")": "(Not Redeem Yet)"}
                </Typography>
                <Typography style={{ color: "white", marginRight: 10 }} variant="caption">
                    {"Redeem Times: " + this.state.redeemTime}
                </Typography>
                <Button variant={"contained"} style={{ opacity: .8 }} onClick={()=> this.setState({ open: true })}color="inherit" disableElevation>
                  Reset Password
                </Button>
                <Button variant={"contained"} onClick={()=> this.setState({ login: false })} color="inherit" disableElevation>
                  Log out
                </Button>

               
              </Grid>

              <ExchangeList storeId={this.state.email.toUpperCase()}/>
            </Grid>
            
          </Grid>)
        }

        return (
            <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Store Panel Sign in
                </Typography>
                <form className={classes.form} noValidate  onSubmit={this.handleSignIn} >
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Store Id"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.handleChange}
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.passwordhandleChange}
                    />
                    <FormControlLabel
                    control={<Checkbox checked={this.state.isChecked} value="remember" color="primary"  onChange={this.onChangeCheckbox}/>}
                    label="Remember me"
                    />

                    <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.submit}
                    >
                    Sign In
                    </Button>

                    <Box mt={5}>
                    <Copyright />
                    </Box>
                </form>
                </div>
            </Grid>
            </Grid>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);