import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

export default function DisableElevation() {

    const [total, setTotal] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [loadingCount, setLoadingCount] = React.useState(0);

    const changeTotal = (event) => {
        setTotal(event.target.value)
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const writeData = () => {

        if(total > 0){
            setLoading(true)

            let vapeRef = firebase.database().ref('vape');
    
            vapeRef.get().then((snapshot) => {
                if(snapshot.exists()){
    
                    var luckdrawfrom = snapshot.numChildren() +1;
                   
                    setLoadingCount(0)
    
                    var selected = [];
    
                    for(var i = 0; i < total; i++) {
                        selected.push(i.toString());
                    }
                    
                    var time = 10;
                    selected.forEach((child, index) => {
                        setTimeout(function() {
                            
                            var number = (Math.random()+' ').substring(2,10)+(Math.random()+' ').substring(2,10);
    
                            var postData = {
                                id: number,
                                created: firebase.database.ServerValue.TIMESTAMP,
                                lucky_number: NumberWithLeadingZeroes(luckdrawfrom + index)
                            };
                            var updates = {};
                            updates['/vape/'+number] = postData;
    
                            firebase.database().ref().update(updates);
                            
                            setLoadingCount(index)
                            
                            if(index == total -1){
                                setLoading(false)
                            }
                        }, time);
                        time += 10;
                    })
    
                    
    
                }
            })
        }
        
    }

    const NumberWithLeadingZeroes = (n) => {
        if ( n< 10 )
        {
            return ( '00000' + n.toString () );
        }
        else if ( n< 100 )
        {
            return ( '0000' + n.toString () );
        }
        else if ( n< 1000 )
        {
            return ( '000' + n.toString () );
        }
        else if ( n< 10000 )
        {
            return ( '00' + n.toString () );
        }
        else if ( n< 100000 )
        {
            return ( '0' + n.toString () );
        }
        else
        {
            return ( n);
        }
    }

    if (loading) {
        return <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogContent style={{ display: 'flex', justifyContent: 'center'}}>
            <Typography>{total + " / " + loadingCount}</Typography>
          </DialogContent>
          <DialogContent style={{ display: 'flex', justifyContent: 'center', marginBottom: 15}}>
            <CircularProgress color="primary" />
          </DialogContent>
          </Dialog>;
      }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Generate New ID"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   {"Are you sure want to generate " + total + " ID ?"} 
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    no
                </Button>
                <Button onClick={()=> {
                    writeData();
                    setOpen(false);
                }} color="primary" autoFocus>
                    yes
                </Button>
                </DialogActions>
            </Dialog>
        <Grid style={{ width: 300 }}>
            <Grid>
                <TextField
                    id="outlined-number"
                    label="Number"
                    type="number"
                    value={total}
                    style={{ width: "100%"}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ min: 1 }}
                    variant="outlined"
                    onChange={changeTotal}
                    />
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Button style={{ width: "100%"}} variant="contained" color="primary" disableElevation onClick={handleClickOpen}>
                Generate new ID
                </Button>
            </Grid>
            
        </Grid>
        </div>
    );
}