import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AnimatedNumber from 'react-animated-number';
import Button from '@material-ui/core/Button';
import Counter from 'react-number-counter';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import firebase from "firebase";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import app from "../firebase/base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "center"
  },
  formControl: {
    minWidth: 250,
  },
}));


export default function SimplePaper() {
  const classes = useStyles();
  const [folder, setFolder] = React.useState('all');
  const [listFolder, setListFolder] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {

    let settingRef = firebase.database().ref('lucky_settings2');

    settingRef.once('value', snapshot => {
      if(snapshot.exists()){
            if(snapshot.val().folder_selected){
                setFolder(snapshot.val().folder_selected)
            }
      }
    })


    let folderRef = firebase.database().ref('customer_form_folder');

    folderRef.once('value', snapshot => {
      if(snapshot.exists()){
        var listFolder = [];
        snapshot.forEach(child => {

            var folder_value = child.val();
            folder_value.id = child.key
            listFolder.push(folder_value)
        })

        setListFolder(listFolder)
        setLoading(false)
      }
    })

  }, []);

  const handleChange = (event) => {
    setFolder(event.target.value);
  };

  const handleSubmit = () => {
    if(folder === "all"){
        let luckyRef = firebase.database().ref('lucky_settings2');
        luckyRef.update({ folder_selected: null })
    }
    else{
        let luckyRef = firebase.database().ref('lucky_settings2');
        luckyRef.update({ folder_selected: folder })
    }
    
  };

  const handleSignout = () => {
    app.auth().signOut();
  };

  if (loading) {
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center',marginTop: 30  }}>
      <CircularProgress color="primary" /></div>;
  }

  return (
    <div className={classes.root}>
        <Grid style={{ flexDirection: "column", display: 'flex', justifyContent: "center", alignItems: "center"}}>
        <Grid>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Select Folder</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={folder}
                onChange={handleChange}
                label="Select Folder"
                >
                <MenuItem value="all">
                    <em>All</em>
                </MenuItem>
                {listFolder.map(val => {
                    return(<MenuItem value={val.id}>
                    <em>{val.name}</em>
                </MenuItem>)
                })}
                </Select>
            </FormControl>
        </Grid>
        <Grid>
            <Button onClick={handleSubmit} className={classes.formControl} style={{ marginTop: 20 }} variant="outlined">Apply</Button>
        </Grid>

        <Grid>
            <Button onClick={handleSignout} className={classes.formControl} style={{ marginTop: 100 }} >Logout</Button>
        </Grid>
        </Grid>
    </div>
  );
}