import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from "firebase";
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';
import DialogContentText from '@material-ui/core/DialogContentText';
import QRCode from "qrcode.react";
import JSZip from "jszip";
import FileSaver from 'file-saver';
import LinkIcon from '@material-ui/icons/Link';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactExport from 'react-data-export';
import { TrafficRounded } from '@material-ui/icons';
import Link from '@material-ui/core/Link';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'product_id', numeric: false, disablePadding: false, label: 'Product Id' },
  { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
  { id: 'registered_date', numeric: true, disablePadding: false, label: 'Registered Date' },
  { id: 'control', numeric: true, disablePadding: false, label: '' },
];

const headMobileCells = [
  { id: 'product_id', numeric: false, disablePadding: false, label: 'Product Id' },
  { id: 'control', numeric: true, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,  matches } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!matches ? headMobileCells.map((headCell) => (
          <TableCell
            style={{padding: 3}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )) : headCells.map((headCell) => (
          <TableCell
            style={{padding: 3}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onMoveClick, onDeleteAllClick, onclickAddVape, onclickRefresh} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          
        </Typography>
      )}

      {numSelected > 0 ? (
        <Grid style={{ display: 'flex', flexDirection:'row'}}>
          <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onDeleteAllClick} >
            <DeleteIcon />
          </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid style={{ display: 'flex', flexDirection:'row'}}>
          <Tooltip title="Refresh">
            <IconButton aria-label="refresh-vape" onClick={onclickRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onMoveClick: PropTypes.func.isRequired,
  onDeleteAllClick: PropTypes.func.isRequired,
  onclickAddVape: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('registered_date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(0);
  const [searchtxt, setSearchtxt] = React.useState("");
  const [data, setData] = React.useState([]);
  const [selectedinfo, setSelectedinfo] = React.useState(0);
  const [listFolder, setListFolder] = React.useState([]);
  const [loaditem, setLoaditem] = React.useState(true);
  const [QRcount, setQRCount] = React.useState(0);
  const [activeQR, setActiveQR] = React.useState(false);

  const [claimReset, setClaimReset] = React.useState(false);
  const [QRid, setQRid] = React.useState("");
  var zip = new JSZip();
  const [multiDataSet, setMultiDataSet] = React.useState([])
  var count = 0;
  const preventDefault = (event) => event.preventDefault();

  useEffect(() => {
      loadVape();

  }, []);

  const loadVape = () => {

    
    let vapeRef = firebase.database().ref('product_exchange');
    vapeRef.once('value', snapshot => {
     
      var vapes = [];
      var listvapes = [{
        columns: [
            {title: "", width: {wpx: 60}},//pixels width 
            {title: "", width: {wpx: 150}},//pixels width 
            {title: "", width: {wpx: 150}},//pixels width 
            {title: "", width: {wpx: 150}},//pixels width 
            {title: "", width: {wpx: 150}},//pixels width 
            {title: "", width: {wpx: 100}},//char width
            {title: "", width: {wpx: 100}},//char width
            {title: "", width: {wpx: 150}},//char width
            {title: "", width: {wpx: 150}},//char width
            {title: "", width: {wpx: 200}},//char width
            {title: "", width: {wpx: 200}},//char width
            {title: "", width: {wpx: 200}},//char width
        ],
        data: [
            [
              {value: "No.", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Id", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Case Number", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Product Id", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Store Id", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "First Name", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Last Name", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Phone", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Birthday", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Email", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Address", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
              {value: "Register Date", style: { alignment: { horizontal: "center" }, font: {sz: "11", bold: true}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }, font: {bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFffd200"}}}},
            ]
        ]
    }];
      var no = 1;
      snapshot.forEach((child) => {

        if(props.storeId == child.val().store_id.toUpperCase()){
          
          var vapes_value = child.val();
          vapes_value.id = child.key;
          vapes_value.product_id = child.val().product_id ? child.val().product_id : "";
          vapes_value.store_id = child.val().store_id ? child.val().store_id : "";
          vapes_value.name = child.val().name ? child.val().name : "";
          vapes_value.last_name = child.val().last_name ? child.val().last_name : "";
          vapes_value.gender = child.val().gender ? child.val().gender : "";
          vapes_value.phone = child.val().phone ? child.val().phone : "";
          vapes_value.email = child.val().email ? child.val().email : "";
          vapes_value.birthday = child.val().birthday ? child.val().birthday : "";
          vapes_value.fullname = vapes_value.last_name + " " + vapes_value.name;
          vapes_value.claim = child.val().claim ? child.val().claim : "";
          vapes_value.address = child.val().address ? child.val().address : "";
          vapes_value.registered_date = child.val().registered_date ? child.val().registered_date : "";
          vapes.push(vapes_value);

          var value_data = [
            {value: no++, style: { alignment: { horizontal: "left" }, font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.id, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: "CN" + padLeadingZeros(vapes_value.case_number, 6), style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.product_id, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.store_id, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.name , style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.last_name, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.phone, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.birthday, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.email, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.address, style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
            {value: vapes_value.registered_date != "" ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(vapes_value.registered_date) : "", style: { font: {sz: "11"}, border: { top: { style: "thin", color: {rgb: "FF000000"}}, bottom: { style: "thin", color: {rgb: "FF000000"}}, left: { style: "thin", color: {rgb: "FF000000"}}, right: { style: "thin", color: {rgb: "FF000000"}} }}},
          ]
          listvapes[0].data.push(value_data)
        }
        
      })
      
      setMultiDataSet(listvapes)
      setLoaditem(false)
      setData(vapes)
      setRows(vapes)
      setLoading(false)
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
      setSelected([]);
      setSelectedFolder("Default");

    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadAllQR = () => {  
    count = 0;
    
    setActiveQR(true)
    
    renderQR()
  };

  const renderQR = () => {

    setQRid(rows[count].id)
    setQRCount(count)

    setTimeout(() => {
      putintoZip();
    }, 10);
    
  }

  const putintoZip = () => {

    const canvas = document.getElementById("S"+ rows[count].id);
    canvas.toBlob(function (blob) {
      zip.file(rows[count].id + ".png", blob);

      if(count < rows.length - 1){
        count++;
        console.log(count)
        renderQR();
      }
      else
      {
        
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          FileSaver.saveAs(content, "Sp2sLuckyDrawQRcode.zip");
          setActiveQR(false)
        });
      }
      
    });
  }

  const search = event => {

    if(searchValue == 0){
      var filterData = data.filter(item => item.id ? item.id.includes(event.target.value): "");

      
      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    else if(searchValue == 1){

      var filterData = data.filter(item => item.fullname ? item.fullname.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    else if(searchValue == 2){

      var filterData = data.filter(item => item.phone ? item.phone.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    else if(searchValue == 3){
      var filterData = data.filter(item => item.email ? item.email.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
      
    }
    else if(searchValue == 4){
      var filterData = data.filter(item => item.lucky_number ? item.lucky_number.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
      
    }
    
    setSearchtxt(event.target.value)
    setPage(0)
  }

  const onChangeSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
    setRows(data)
  }

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [selectedFolder, setSelectedFolder] = React.useState("Default");
  const [total, setTotal] = React.useState(1);

  const changeTotal = (event) => {
      setTotal(event.target.value)
  }
  
  const [info, setInfo] = React.useState("");
  
  const onInfoClick = (info) => {
    setInfo(info);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
    setSelected([]);
    setSelectedFolder("Default");
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const openLink = (id) => {
    window.open("https://authorize.sp2smalaysia.com/warrantly/" + id, "_blank")
  }

  const onDeleteClick = (info) => {
    setSelectedinfo(info)
    setClaimReset(false)
    setOpen2(true);
    
  }

  const onclickClaimReset = (info) => {
    setSelectedinfo(info)
    setClaimReset(true)
    setOpen2(true);
    
  }

  const confirmReset = () => {

    firebase.database().ref('product_exchange/' + selectedinfo.id).remove()
    
    setOpen2(false);

    setLoadingCount(0)
    loadVape();
    setLoading(true)
    setOpen4(false);
    
  }

  const confirmMove = () => {
    setLoadingCount(0)
    var time = 10;
    selected.forEach((child, index) => {
      setTimeout(function() {
        firebase.database().ref('product_exchange/' + child).update({ folder: selectedFolder == "Default" ? null : selectedFolder });
        setLoadingCount(index)
        if(index == selected.length -1){
          loadVape();
        }
      }, time);
      time += 10;
      //
    });
    
    setLoading(true)
    setLoadingCount(0)
  }

  const confirmDelete = () => {

    setLoadingCount(0)
    var time = 50;
    selected.forEach((child, index) => {
      setTimeout(function() {
        firebase.database().ref('product_exchange/' + child).remove();
        setLoadingCount(index)
        if(index == selected.length -1){
          loadVape();
        }
      }, time);
      time += 50;
      //
    });
    
    setLoading(true)
    setLoadingCount(0)
    setOpen4(false);
  }

  const onMoveClick = () => {
    setOpen3(true);
  }

  const onDeleteAllClick = () => {
    setOpen4(true);
  }

  const onclickAddVape = () => {
    setTotal(1)
    setOpen5(true);
  }

  const confirmAddVape = () => {
    setOpen5(false);
  }

  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value)
  }

  const onclickRefresh = () => {
    setLoaditem(true)
    loadVape();
  }

  const padLeadingZeros = (num, size) => {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  const writeData = () => {

    if(total > 0){

        var selectedvape = [];

        for(var i = 0; i < total; i++) {
          selectedvape.push(i.toString());
        }

        setLoading(true)
        setLoadingCount(0)
        setSelected(selectedvape)

        let vapeRef = firebase.database().ref('vape');

        vapeRef.get().then((snapshot) => {
            if(snapshot.exists()){

                var luckdrawfrom = snapshot.numChildren() +1;
               
                var time = 50;
                selectedvape.forEach((child, index) => {
                    setTimeout(function() {
                        
                        var number = (Math.random()+' ').substring(2,10)+(Math.random()+' ').substring(2,10);

                        var postData = {
                            id: number,
                            created: firebase.database.ServerValue.TIMESTAMP,
                            lucky_number: NumberWithLeadingZeroes(luckdrawfrom + index),
                            folder: props.folder? props.folder: null
                        };
                        var updates = {};
                        updates['/vape/'+number] = postData;

                        firebase.database().ref().update(updates);
                        
                        setLoadingCount(index)
                        
                        if(index == total -1){
                            loadVape()
                        }
                    }, time);
                    time += 50;
                })

                

            }
        })
    }
    
  }

  const NumberWithLeadingZeroes = (n) => {
      if ( n< 10 )
      {
          return ( '00000' + n.toString () );
      }
      else if ( n< 100 )
      {
          return ( '0000' + n.toString () );
      }
      else if ( n< 1000 )
      {
          return ( '000' + n.toString () );
      }
      else if ( n< 10000 )
      {
          return ( '00' + n.toString () );
      }
      else if ( n< 100000 )
      {
          return ( '0' + n.toString () );
      }
      else
      {
          return ( n);
      }
  }

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  if (loading) {
    return <Dialog
    open={true}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
      <DialogContent style={{ display: 'flex', justifyContent: 'center'}}>
        <Typography>{selected.length + " / " + loadingCount}</Typography>
      </DialogContent>
      <DialogContent style={{ display: 'flex', justifyContent: 'center', marginBottom: 15}}>
        <CircularProgress color="primary" />
      </DialogContent>
      </Dialog>;
  }

  if (loaditem) {
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center',marginTop: 30  }}>
      <CircularProgress color="primary" /></div>;
  }

  var d = new Date();
    var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + "-" + 
    d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

    const moment = require('moment');

  return (
    <div className={classes.root}>

      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Vape"}</DialogTitle>
        <DialogContent>
          <Grid style={{ width: 300 }}>
              <Grid>
                  <TextField
                      id="outlined-number"
                      label="Number"
                      type="number"
                      value={total}
                      style={{ width: "100%"}}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      inputProps={{ min: 1 }}
                      variant="outlined"
                      onChange={changeTotal}
                      />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} color="primary">
            Cancel
          </Button>
          <Button onClick={writeData} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Move"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete these exchange?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Move"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to move these vapes to selected folder?
          </DialogContentText>
        </DialogContent>
        <DialogContent>
        <FormControl variant="outlined" style={{ width: '100%'}}>
          <InputLabel id="demo-simple-select-outlined-label">Folder</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedFolder}
            onChange={handleFolderChange}
            label="Folder"
          >
            <MenuItem value={"Default"}>
                  {"Default"}
            </MenuItem>
            {listFolder.map((value, index)=>{

              return(
                <MenuItem value={value.id}>
                  {value.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmMove} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {"Are you sure you want to delete" }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            cancel
          </Button>
          <Button onClick={confirmReset} color="primary" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Info
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 300 }}>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Id
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.id}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Case Number
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {"CN" + padLeadingZeros(info.case_number, 6)}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Brand of POD
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.brand_pod}
              </Typography>
            </Grid>
            {info.others_brand_pod ? <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Others brand of POD
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.others_brand_pod}
              </Typography>
            </Grid> : null}
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Product Id
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.product_id}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
               Store Id
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }}  gutterBottom>
                {info.store_id}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Registered date
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.registered_date ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(info.registered_date):"-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                First Name
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.name ? info.name : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Last Name
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.last_name ? info.last_name : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Phone number
              </Typography>
              <Typography variant="body2"  style={{ marginTop: -5 }} gutterBottom>
                {info.phone ? info.phone : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Birthday
              </Typography>
              <Typography variant="body2"  style={{ marginTop: -5 }} gutterBottom>
                {info.birthday ? info.birthday : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Address
              </Typography>
              <Typography variant="body2"  style={{ marginTop: -5 }} gutterBottom>
                {info.address ? info.address : "-"}
              </Typography>
            </Grid>
            <Grid >
              <Typography variant="body2" style={{ fontWeight: "bold"}} gutterBottom>
                Email
              </Typography>
              <Typography variant="body2" style={{ marginTop: -5 }} gutterBottom>
                {info.email ? info.email : "-"}
              </Typography>
            </Grid>
            
        </DialogContent>
      </Dialog>
      <Paper className={classes.paper} style={{ padding: 10 }}>
        <Grid container style={{ display:'flex',}}>
          <Grid item xs={6}>
          <Tooltip title="Download Exchange List">
            <ExcelFile element={
            <Button style={{ width:"100%", whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" startIcon={<GetAppIcon />} color="primary" disableElevation>
            Exchange List
            </Button>}  filename={"SP2s_Exchanges_" + datestring}>
                <ExcelSheet dataSet={multiDataSet} name="Exchange"/>
            </ExcelFile>
          </Tooltip>
          </Grid>
         
        </Grid>
            
        <EnhancedTableToolbar onclickRefresh={onclickRefresh} numSelected={selected.length} onMoveClick={onMoveClick} onDeleteAllClick={onDeleteAllClick} onclickAddVape={onclickAddVape}/>
        <TableContainer>
          <Table
            className={classes.table}
            
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              matches={matches}
            />
            <TableCell padding={'none'}  colSpan={6}>
              
              <Grid container>
                <Grid item md={10} xs={12}>
                <FormControl style={{ marginTop:10 }}  fullWidth >
                    <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                    <Input onChange={(e) => search(e)}
                      value={searchtxt}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment style={{ marginLeft:20 }} position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={12} style={{marginTop:10, paddingLeft: 15}}>
                  <FormControl style={{ width: "100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">Search by</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={searchValue}
                        label="Search by"
                        onChange={(e) => onChangeSelect(e)}
                      >
                        <MenuItem value={0}>Id</MenuItem>
                        <MenuItem value={1}>Name</MenuItem>
                        <MenuItem value={2}>Phone Number</MenuItem>
                        <MenuItem value={3}>Email</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </TableCell>
            <TableBody >
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  var warranty_date = null

                  if(row.registered_date ){
  
                    const moment = require('moment');

                    const timestamp = row.registered_date;
                    warranty_date = moment(timestamp).add(12, 'M').format('DD-MM-YYYY');

                    console.log(warranty_date)
                  }
                  

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                     
                    >
                      {!matches ? null : <TableCell style={{padding: 3}} component="th" id={labelId} scope="row" padding="none">
                      {row.id}
                      </TableCell>}
                      <TableCell style={{padding: 3}} component="th" id={labelId} scope="row" padding="none">
                      {row.product_id}
                      </TableCell>
                      {!matches ? null : <TableCell style={{padding: 3}} align="left">{row.name && row.last_name ? row.last_name + " " + row.name  : "-"}</TableCell>}
                      {!matches ? null : <TableCell style={{padding: 3}} align="left">{row.registered_date ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(row.registered_date): "-"}</TableCell>}
                      <TableCell style={{padding: 3}} align="left">
                        <Tooltip title="Info">
                          <IconButton onClick={() => onInfoClick(row) }>
                              <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"per page"}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      
    </div>
  );
}