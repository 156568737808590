import React, { Component } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import app from "../../firebase/base";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      SP2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    height: '100vh',
    
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/sp2s-luckydraw.appspot.com/o/Login%20image%2Flogin.jpg?alt=media&token=0a56c619-35dc-45d6-a3ae-b05f64f23bcf)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  recaptcha: {
    marginTop: theme.spacing(1),
  }
});

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.captcha = null;
        this.state = {
          isVerified: true,
          email: '',
          password: '',
          isChecked: false,
        }
    }

    onVerify = async () => {
        this.setState({
          isVerified: true
        });
    };
    
    onExpire = () => {
        this.setState({
            isVerified: false
        });
    };

    handleSignIn = async event => {

        event.preventDefault();
    
        if (this.state.isVerified) {
          const { email, password, isChecked } = this.state;

          
          if (isChecked && email !== "") {
            localStorage.username = email
            localStorage.password = password
            localStorage.checkbox = isChecked
          }
          else
          {
            localStorage.username = ""
            localStorage.password = ""
            localStorage.checkbox = false
          }

          try {
            await app
              .auth()
              .signInWithEmailAndPassword(email, password);
              
          } catch (error) {
            alert(error);
          }

        
        } else {
          alert('Please verify that you are a human!');
        }
        
    };

    handleChange = event => {
        this.setState({ email: event.target.value });
    };

    passwordhandleChange = event => {
        this.setState({ password: event.target.value });
    };

    componentDidMount() {
       
        if (localStorage.checkbox && localStorage.username !== "") {
            
            this.setState({
                isChecked: true,
                email: localStorage.username,
                password: localStorage.password
            })
        }
    }

    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    render() {

        const { classes } = this.props;

        return (
            <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate  onSubmit={this.handleSignIn} >
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.handleChange}
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.passwordhandleChange}
                    />
                    <FormControlLabel
                    control={<Checkbox checked={this.state.isChecked} value="remember" color="primary"  onChange={this.onChangeCheckbox}/>}
                    label="Remember me"
                    />

                    <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.submit}
                    >
                    Sign In
                    </Button>

                    <Box mt={5}>
                    <Copyright />
                    </Box>
                </form>
                </div>
            </Grid>
            </Grid>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);