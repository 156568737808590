import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import axios from "axios";

import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
  

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%",
    height: "auto",
    backgroundColor: "#1a1a1a",
    color: "white",
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [genuine, setGenuine] = React.useState(false);
  const [vapeid, setVapeid] = React.useState("");
  const [isvalid, setIsvalid] = React.useState(true);
  const [nextPage, setNextPage] = React.useState(false);

  const theme = createMuiTheme({
    palette: {
        primary: {
          light: '#99d700',
          main: '#99d700',
          dark: '#99d700',
        },
        secondary: {
          light: '#c90808',
          main: '#a60000',
          dark: '#930000',
        },
      type: 'dark',
    },
    
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  })

  const hancleAgainClick = (event) => {
    setVapeid("")
    setNextPage(false)
  }

  const hancleClick = (event) => {

    if(vapeid.replaceAll(/\s/g,'').length == 16){
      setIsvalid(true)

      var apiLink = "https://ck.fw-12365.com/ashx/SecurityQueryApi.ashx?Key=tc201805051108&FWCode=" + vapeid.replaceAll(/\s/g,'') + "&Lang=en-gb"

      /*fetch(apiLink)
        .then(response => response.json())
        .then(data => {
            if(data.RecordList.length > 0){
              setGenuine(true)
            }
            else
            {
              setGenuine(false)
            }

            setNextPage(true)
          });*/

          axios.get(apiLink).then((response) => {
            if(response.data.State == 1 || response.data.State == 2){
              setGenuine(true)
            }
            else
            {
              setGenuine(false)
            }

            setNextPage(true)
          });
    }
    else
    {
      setIsvalid(false)
    }
  }


  const handleVapeIDChange = (event) => {
    setVapeid(event.target.value)
  }

  if(nextPage){
    return (<ThemeProvider theme={theme}>
        <div className={classes.root}>
        <Grid style={{ padding: 20 }}>

          <Grid style={{ display:'flex', justifyContent: "center" }} >
            <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
          </Grid>
          <Divider style={{ opacity:.3 }}/>

          <Grid style={{  marginTop: 40, display:'flex', justifyContent: "center" }} >
            <img style={{ height: 160, marginBottom: 15 }} src={genuine ? '/correct.png' :'/warning.png'}/>
          </Grid>
          <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h5">{genuine ? 'Authentic product':'Counterfeit WARNING'}</Typography>
          <Typography style={{ textAlign:"center", opacity:.5 }} variant="body1">{genuine ?'This product is 100% Genuine': 'This product could potentially be counterfeit.'}</Typography>
          <Typography style={{ textAlign:"center", opacity:.5 }} variant="body1">{genuine ?'thank you for purchasing our product': 'Please report this to our customer service team by tapping Report this product below.'}</Typography>

          

          <Button onClick={hancleAgainClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 20, width: "100%" }} variant="contained" disableElevation color="primary">Verify Again</Button>
          </Grid>
        </div>
    </ThemeProvider>)
  }
  
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid style={{ padding: 20 }}>

              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h4">Your Right to Know</Typography>
              <Typography style={{ textAlign:"center", opacity:.5 }} variant="h6">Verify your product through the methods below.</Typography>
              <Typography style={{ marginTop: 15, textAlign:"center", opacity:.5 }} variant="body1">- For Devices / Pods -</Typography>
              
              <Grid container style={{ width: "100%", marginTop: 25, maxWidth: 700 }}>
                
                <Grid md={5} item xs={12} style={{ marginBottom: 30 }}>
                  <Grid container >
                  
                    <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isvalid ?false: true}
                          id="vapeid"
                          style={{ marginTop: 15, width: "100%" }}
                          value={vapeid}
                          onChange={handleVapeIDChange}
                          label="Enter the 16-digit code"
                          variant="outlined"
                          fullWidth
                          inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                          helperText={isvalid ? "": "Please check the input information is correct or not and enter the 14-digit correct authentification code."}
                        />
                        </form>
                    </Grid>
                    
                  </Grid>

                  <Button onClick={hancleClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 20, width: "100%" }} variant="contained" disableElevation color="primary">Verify</Button>
                </Grid>

                <Grid md={7} item xs={12} style={{ paddingLeft: 50 }}>
                  <img style={{ width: "100%", maxHeight: 500 }} src='/Authencation_code.png'/>
                </Grid>
              </Grid>
            </Grid>
            </MuiPickersUtilsProvider>
        </div>
        </ThemeProvider>
      );
  

  
}