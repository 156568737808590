import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AnimatedNumber from 'react-animated-number';
import Button from '@material-ui/core/Button';
import Counter from 'react-number-counter';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "center",
  },
  formControl: {
    minWidth: 300,
    marginTop: 20
  },
}));

var count = 5;
var picknum = "000000";

export default function SimplePaper() {
  const classes = useStyles();
  const [number, setNumber] = React.useState({ 
      number1: 0,
      number2: 0,
      number3: 0,
      number4: 0,
      number5: 0,
      number6: 0,
    });
  const [text, setText] = React.useState("GO");
  const [loading, setLoading] = React.useState(true);
  const [info, setInfo] = React.useState([]);
  const [draw, setDraw] = React.useState("");
  const [countPerson, setCountPerson] = React.useState(1);
  const [award, setAward] = React.useState("consolation");
  const [listVape, setListVape] = React.useState([]);
  const [folderSelected, setFolderSelected] = React.useState("");
  const [pickedList, setPickedList] = React.useState([]);

  useEffect(() => {

    let settingRef = firebase.database().ref('lucky_settings');

    settingRef.once('value', snapshot => {
      if(snapshot.exists()){
            if(snapshot.val().folder_selected){
                loadVape(snapshot.val().folder_selected)

                let pickedRef = firebase.database().ref('lucky_picked/' + snapshot.val().folder_selected);

                pickedRef.once('value', childsnapshot => {

                    if(childsnapshot.exists()){
                        
                        var listVape = [];
                        childsnapshot.forEach(child => {

                            var vape_value = child.val();

                            listVape.push(vape_value)

                        })

                        setPickedList(listVape)
                    }
                })
            }
      }
      else{
        loadVape(null)
      }
    })


    

    

  }, []);

  const loadVape = (folderId) => {
    let vapeRef = firebase.database().ref('vape');

    vapeRef.once('value', snapshot => {
      if(snapshot.exists()){
        var listVape = [];
        snapshot.forEach(child => {

            var vape_value = child.val();
            vape_value.id = child.key

            if(folderId){
                if(vape_value.folder === folderId){
                    listVape.push(vape_value)
                }
            }
            else{
                listVape.push(vape_value)
            }

        })

        setListVape(listVape)
        setLoading(false)
        setFolderSelected(folderId)
      }
    })
  }

  const pickNumber = () => {

    picknum ="000000";
    
    var registered_user = [];

    listVape.forEach(child => {

        if(child.registered_date && !child.award){

            var currentIndex = pickedList.map(val => { return val.id}).indexOf(child.id)
            if(currentIndex === -1){
                registered_user.push(child)
            }
            
        }

    })

    if(registered_user.length > 0){
      
      count = 5;
      var personInfo = [];
      for(var i =0; i < countPerson; i++){
        var pickNumber = Math.floor(Math.random() * ((registered_user.length) - 0))
        picknum = registered_user[pickNumber].lucky_number;
        
        registered_user[pickNumber].award = award;
        registered_user[pickNumber].picked = firebase.database.ServerValue.TIMESTAMP;
        let luckyRef = firebase.database().ref('lucky_picked/' + folderSelected );
        luckyRef.push(registered_user[pickNumber])

        personInfo.push(registered_user[pickNumber])
      }
        

        setInfo(personInfo);
        randomNumber();
        countdown();
    }
    else
    {
        setText("GO")
    }


    setText("Ready")
    setDraw("draw")
    
  }

  const countdown = () => {
    if(count == 0){
        setText("GO")
        
    }
    else
    {

        setText(count)
        
        setTimeout(() => {
            count--;
            countdown();
        }, 1000)
    }
  }

  const onResetClick = () => {
    setText("GO")
    setDraw("")
    var randomNumber1 ={ 
        number1: 0,
        number2: 0,
        number3: 0,
        number4: 0,
        number5: 0,
        number6: 0,
      }

    setNumber(randomNumber1)
  }

  const handleChange = (event) => {
    setAward(event.target.value);
  };

  const handleChangeCountPerson = (event) => {
    setCountPerson(event.target.value);
  }

  const randomNumber = () => {


    if(count != 0){
        var randomNumber1 = {
            number1: Math.floor(Math.random() * (10 - 0)),
            number2: Math.floor(Math.random() * (10 - 0)),
            number3: Math.floor(Math.random() * (10 - 0)),
            number4: Math.floor(Math.random() * (10 - 0)),
            number5: Math.floor(Math.random() * (10 - 0)),
            number6: Math.floor(Math.random() * (10 - 0)),
        }
    
        setTimeout(() => {
            setNumber(randomNumber1)
            randomNumber()
        }, 50)
    }
    else{

        var randomNumber1 = {
            number1: picknum.charAt(0),
            number2: picknum.charAt(1),
            number3: picknum.charAt(2),
            number4: picknum.charAt(3),
            number5: picknum.charAt(4),
            number6: picknum.charAt(5),
        }

        setNumber(randomNumber1)
        setDraw("done")
    }
    
  }

  if (loading) {
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center',marginTop: 30  }}>
      <CircularProgress color="primary" /></div>;
  }

  let arr = Array.apply(null, {length: countPerson}).map(Number.call, Number);

  return (
    <div className={classes.root}>
         <Grid style={{ position: "absolute"}}>
            <img height={250} src="/luckydraw_title.png"/>
        </Grid>
        <Grid >

                <Grid style={{ border: "5px solid rgba(0, 0, 0, 0.8)", marginTop: 130, padding: 20, display:"flex", flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number1}</Typography>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number2}</Typography>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number3}</Typography>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number4}</Typography>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number5}</Typography>
                    <Typography variant="h4" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)", borderRight: "1px solid rgba(0, 0, 0, 0.3)", fontWeight: 'bold', padding: 12}}>{number.number6}</Typography>
                </Grid>

                {draw == "done" ? <Typography gutterBottom align="center" variant="h4" color="secondary"  style={{ marginTop: 10,  fontWeight: 'bold' }}>
                    Congratulations!
                    </Typography> : null }
                {draw == "done" ? <Typography gutterBottom align="center"  variant="h6" color="secondary" style={{ marginTop: 0, fontWeight: 'bold' }}>
                    {"The Winner is"}
                    </Typography> : null }
                   
                    {draw == "done" ? 
                    info.map(val =>  { return <Typography gutterBottom align="center"  variant="h6" style={{ marginTop: -10, maxWidth:350, fontWeight: 'bold' }}>
                        {val.last_name + " " + val.name}
                    </Typography> })
                    : null }

                    {draw == "done" ? null :<Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button disabled={draw == "draw" || draw == "done" ? true: false}  style={{  borderRadius: 100, marginTop: 30, width: 120, height:120,fontSize: 30, fontWeight: 'bold'}} variant="contained" color="secondary" onClick={()=>pickNumber()}>
                        {text}
                        </Button>
                    </Grid>}

                    {draw == "done" ? <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{  borderRadius: 100, marginTop: 10, width: 120, height:120,fontSize: 30, fontWeight: 'bold'}} variant="contained" onClick={onResetClick}>
                        {"Reset"}
                        </Button>
                    </Grid> : null }
                    {draw == "done" ? null :<Grid style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                    <img height={80} src="/sp2slogo.png"/>
                    </Grid> }

                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Award</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={award}
                            onChange={handleChange}
                            label="Award"
                            >
                            <MenuItem value="consolation">
                                <em>Consolation Prize : SP2S II Device</em>
                            </MenuItem>
                            <MenuItem value="champion">
                                <em>1st Prize RM10,000</em>
                            </MenuItem>
                            <MenuItem value="runnerup">
                                <em>2nd Prize Rm3,888</em>
                            </MenuItem>
                            <MenuItem value="thirdplace">
                                <em>3rd Prize RM2,888</em>
                            </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                      <TextField
                        id="filled-number"
                        label="Number of Lucky Person"
                        type="number"
                        style={{ width: "100%", margin: 10}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChangeCountPerson}
                        value={countPerson}
                        InputProps={{ inputProps: { min: 1, max: 20 } }}
                        variant="filled"
                      />
                      </Grid>

        </Grid>

    </div>
  );
}