import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import EmailValidator from 'email-validator';
import MuiPhoneInput from 'material-ui-phone-number';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%"
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [serial, setSerial] = React.useState(0);
  const [redeem, setRedeem] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
  });
  const [name, setName] =  React.useState("");
  const [phone, setPhone] =  React.useState("");
  const [email, setEmail] =  React.useState("");
  const [others, setOthers] =  React.useState("");
  const [isname, setIsname] =  React.useState(true);
  const [isphone, setIsphone] =  React.useState(true);
  const [isemail, setIsemail] =  React.useState(true);
  const [isshoptype, setShoptype] =  React.useState(true);
  const [isother, setIsother] =  React.useState(true);
  
  const handleYes = () => {

    const result = EmailValidator.validate(email);
    var phone_result = false;
    var name_result = false;
    var check_result = false;
    var other_result = false;

    if(phone.length > 7)
    {
      phone_result = true;
      setIsphone(true);
    }
    else
    {
      setIsphone(false);
    }

    if(name.length > 2)
    {
      name_result = true;
      setIsname(true)
    }else{
      setIsname(false)
    }

    if(result == true){
      setIsemail(true)
    }
    else
    {
      setIsemail(false)
    }

    if(others.length > 2)
    {
      setIsother(true)
    }else{
      setIsother(false)
    }

    if(state.checkedA == true || 
      state.checkedB == true ||
      state.checkedC == true ||
      state.checkedD == true ||
      state.checkedE == true){
        check_result= true;
        setShoptype(true)
      }
      else
      {
        setShoptype(false)
      }
    
    console.log(result)
    console.log(phone_result)
    console.log(name_result)
    console.log(check_result)

    if(result == true && phone_result == true && name_result == true &&  check_result == true){
      setOpen(false);
        firebase.database().ref('/voucher/'+props.match.params.id).update({
            collected: true,
            collected_date: firebase.database.ServerValue.TIMESTAMP,
            name:name,
            phone: phone,
            email: email,
            lazada: state.checkedA,
            shopee: state.checkedB,
            seller: state.checkedC,
            store: state.checkedD,
            other: state.checkedE,
            other_content:  others
        });
    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const hancleClick = (event) => {
    setOpen(true);
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleOtherChange = (event) => {
    setOthers(event.target.value)
  }

  const handlePhoneChange = (value) => {
    setPhone(value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  

  const handleChecBoxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };



  useEffect(() => {
    
    let vapeRef = firebase.database().ref('voucher/'+props.match.params.id);

    vapeRef.on('value', snapshot => {
     
      if(snapshot.exists()){
          setSerial(snapshot.val().id)
          setLoading(false)
          setRedeem(snapshot.val().collected)
      }
      else
      {
          setInvalid(true)
          setLoading(false)
        
      }
    })

  }, []);
  

  if(loading){
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
  }

  if(invalid){
      return(
        <div className={classes.root}>
            {loaded ? null : <Grid style={{ padding: 30, display: 'flex',  justifyContent:'center', alignItems:'center'}} >
                    <CircularProgress color="primary" />
                </Grid> }
        <Grid style={{ maxWidth: 800 }}>
                <Grid style={{ width: "100%"}}>
                    <img
                    onLoad={()=> setLoaded(true)}
                    width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/sp2s-luckydraw.appspot.com/o/Voucher%2FVoucher_invalid.jpg?alt=media&token=f6d3ff1a-3494-4e4c-8374-9a618fca5bf8"
                    alt="SP2s InvalidVoucher"
                    />
                </Grid>
            </Grid>
            </div>
            )
  }
  
    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure want to redeem now?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Make sure you at the right place, at the right time before redeeming.
                </DialogContentText>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    error={isname ?false: true}
                    value={name}
                    onChange={handleNameChange}
                    label="Shop name"
                    fullWidth
                  />
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <MuiPhoneInput
                      autoFormat={false}
                      error={isphone ?false: true}
                        style={{ width: '100%', marginTop: 20 }}
                        defaultCountry='my'
                        regions={'asia'}
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                    <Grid md={6} item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        error={isemail ?false: true}
                        value={email}
                        onChange={handleEmailChange}
                        label="Email Address"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedA}
                            onChange={handleChecBoxChange}
                            name="checkedA"
                            color="primary"
                          />
                        }
                        label="Lazada"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedB}
                            onChange={handleChecBoxChange}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Shopee"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedC}
                            onChange={handleChecBoxChange}
                            name="checkedC"
                            color="primary"
                          />
                        }
                        label="Social Media Seller"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedD}
                            onChange={handleChecBoxChange}
                            name="checkedD"
                            color="primary"
                          />
                        }
                        label="Physical Store"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedE}
                            onChange={handleChecBoxChange}
                            name="checkedE"
                            color="primary"
                          />
                        }
                        label="Others"
                      />
                    </FormGroup>
                    {state.checkedE ? <TextField
                    autoFocus
                    error={isother ?false: true}
                    margin="dense"
                    id="others"
                    value={others}
                    onChange={handleOtherChange}
                    label="Others"
                    fullWidth
                  /> : null}
                  </Grid>
                  {isshoptype ? null :<Typography color="secondary" variant="caption">
                    *At least an option must be selected
                  </Typography>}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
                <Button onClick={handleYes} color="primary" autoFocus>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
            <Grid style={{ maxWidth: 800, width: "100%" }}>
                
                
                <Button onClick={hancleClick} disabled={redeem ? true: false} style={{ width: "100%", backgroundColor: redeem ? "#cc3131": "#cc9731", color: 'black', fontWeight: 'bold', fontSize: 24 }} variant="contained" color="primary" disableElevation>
                {redeem ? "The Voucher was redeemed" : "Redeem now!"}
                </Button>
                <Grid style={{ backgroundColor: "#191919", width: "100%"}}>
                    <Typography style={{ color:"white", marginTop: -5, fontSize: 18, fontWeight:'bold', display: 'flex', justifyContent: 'center', padding: 10 }} variant="subtitle1" >
                        { "Serial No: " + serial }
                    </Typography>
                </Grid>
                {loaded ? null : <Grid style={{ padding: 30, display: 'flex',  justifyContent:'center', alignItems:'center'}} >
                    <CircularProgress color="primary" />
                </Grid> }
                <Grid>
                    <img
                    onLoad={()=> setLoaded(true)}
                    width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/sp2s-luckydraw.appspot.com/o/Voucher%2FVoucher_design_12052021.jpg?alt=media&token=be457600-1c98-4244-a7bd-3f94e5229c83"
                    alt="SP2s Voucher"
                    />
                </Grid>
            </Grid>
        </div>
      );
  

  
}