import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Vapes from './Vape_list'
import VapesFolder from './Vape_folder_list'

//tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    background: '#eaeff1',
  }
})

class Index extends Component {

  state = {
    value: 0
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  }

  render(){

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
                component="div"
                color="primary"
                position="static"
                elevation={0}
                >
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                        <Typography color="inherit" variant="h5" component="h1">
                            Vape
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Help">
                        <IconButton color="inherit" >
                            <HelpIcon />
                        </IconButton>
                        </Tooltip>
                    </Grid>
                    </Grid>
                </Toolbar>
        </AppBar>
        <AppBar
            component="div"
            className={classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}
        >
            <Tabs value={this.state.value} onChange={this.handleChange} textColor="inherit">
                <Tab textColor="inherit" label="Vapes"  {...a11yProps(0)}/>
                <Tab textColor="inherit" label="Folder"  {...a11yProps(1)}/>
            </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
            <Vapes folder={null}/>
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
            <VapesFolder/>
        </TabPanel>
        
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Index);