import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navigator from './Navigator';
import Header from './Header';
import Grid from '@material-ui/core/Grid';

function Copyright() {
  return (
    <Grid style={{ display: "flex", justifyContent:"center" }}>
      <Typography variant="caption" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://sp2smalaysia.com/">
          SP2s Malaysia
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Grid>
    
  );
}



const drawerWidth = 150;

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    position: "absolute",
    left: "0",
    width: "100%",
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});

class Paperbase extends Component {
  
  state = {
    mobileOpen : false
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };

  render(){
    const { classes } = this.props;

    return (
      
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator onClose={this.handleDrawerToggle} PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header onDrawerToggle={this.handleDrawerToggle} />
            <main className={classes.main}>
              {this.props.components}
            </main>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
    );
  }

}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Paperbase);