import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LuckyDrawPick from './LuckyDrawPick'
import LuckySettings from './LuckySettings'
import LuckyList from './LuckyList'

//tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = (theme) => ({
  root: {
    width: '100%',
    background: '#eaeff1',
    height: "100vh"
  }
})

class Index extends Component {

  state = {
    value: 0
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  }

  render(){

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
            component="div"
            className={classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}
        >
            <Tabs value={this.state.value} onChange={this.handleChange} textColor="inherit">
                <Tab textColor="inherit" label="Lucky Draw"  {...a11yProps(0)}/>
                <Tab textColor="inherit" label="Lucky Fellow"  {...a11yProps(1)}/>
                <Tab textColor="inherit" label="Settings"  {...a11yProps(2)}/>
            </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
            <LuckyDrawPick/>
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
            <LuckyList/>
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
            <LuckySettings/>
        </TabPanel>
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Index);