import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import MuiPhoneInput from 'material-ui-phone-number';
import Divider from '@material-ui/core/Divider';
import EmailValidator from 'email-validator';


import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
  

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%",
    height: "100%",
    backgroundColor: "#1a1a1a",
    color: "white"
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [serial, setSerial] = React.useState("");
  const [register, setRegister] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [name, setName] =  React.useState("");
  const [lastname, setLastname] =  React.useState("");
  const [phone, setPhone] =  React.useState("");
  const [email, setEmail] =  React.useState("");
  const [gender, setGender] =  React.useState("Female");
  const [birthday, setBirthday] = React.useState("2003-01-01");
        
  const [isname, setIsname] =  React.useState(true);
  const [islastname, setIslastname] =  React.useState(true);
  const [isphone, setIsphone] =  React.useState(true);
  const [isemail, setIsemail] =  React.useState(true);
  const [isgender, setIsgender] =  React.useState(true);
  const [isbirthday, setIsbirthday] = React.useState(true);

  const [luckynumber, setLuckynumber ]= React.useState("");

  const theme = createMuiTheme({
    palette: {
        primary: {
          light: '#99d700',
          main: '#99d700',
          dark: '#99d700',
        },
        secondary: {
          light: '#c90808',
          main: '#a60000',
          dark: '#930000',
        },
      type: 'dark',
    },
    
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  })

  const handleYes = () => {
    setOpen(false);
    firebase.database().ref('/vape/'+props.match.params.id).update({
        registered_date: firebase.database.ServerValue.TIMESTAMP,
        name:name,
        phone: phone,
        email: email,
        gender: gender,
        last_name: lastname,
        birthday: birthday,
    });

  };

  const handleClose = () => {
    setOpen(false);
  };

  const hancleClick = (event) => {

    const result = EmailValidator.validate(email);
    var phone_result = false;
    var name_result = false;
    var last_name_result = false;
    var gender_result = false;

    if(phone.length > 7)
    {
      phone_result = true;
      setIsphone(true)
    }
    else
    {
      setIsphone(false)
    }

    if(name.length > 2)
    {
      name_result = true;
      setIsname(true)
    }
    else
    {
      setIsname(false)
    }

    if(lastname.length > 2)
    {
      last_name_result = true;
      setIslastname(true)
    }
    else
    {
      setIslastname(false)
    }

    if(gender != ""){
      gender_result = true;
      setIsgender(true)
    }
    else
    {
      setIsgender(false)
    }

    if(result == false){
      setIsemail(false)
    }
    else{
      setIsemail(true)
    }

    if(result == true && phone_result == true && name_result == true && gender_result == true && last_name_result == true){
      setOpen(true);
    }

  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleLastNameChange = (event) => {
    setLastname(event.target.value)
  }

  const handlePhoneChange = (value) => {
    console.log(value)
    setPhone(value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    
    let vapeRef = firebase.database().ref('vape/'+props.match.params.id);

    vapeRef.on('value', snapshot => {
     
      if(snapshot.exists()){
          setSerial(snapshot.val().id)
          setLoading(false)
          setRegister(snapshot.val().registered_date);
          setLuckynumber(snapshot.val().lucky_number);
      }
      else
      {
          setInvalid(true)
          setLoading(false)
        
      }
    })

  }, []);

  

  const handleDateChange = (event) => {
    setBirthday(event.target.value);
  };

  if(loading){
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
  }

  if(invalid){
      return(
        <div className={classes.root}>
            {loaded ? null : <Grid style={{ padding: 20 }}>

              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Invalid ID</Typography>
              </Grid>}
            </div>
            )
  }


  if(register){

    const moment = require('moment');

    console.log(register)
    return(
        <ThemeProvider theme={theme}>
          <Grid style={{ padding: 20, backgroundColor: "#1a1a1a", color: "white", height: "100%" }}>
            <Grid style={{ display:'flex', justifyContent: "center" }}>
              <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
            </Grid>
            <Divider style={{ opacity:.3 }}/>
            
            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Your warranty and lucky draw has been registered successfully!</Typography>
            
            <Typography color="primary" style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Your Lucky Number</Typography>
            <Typography color="primary" style={{ textAlign:"center", fontWeight:"bold" }} variant="h2">{luckynumber}</Typography>
            <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Warranty</Typography>
            <Typography style={{ textAlign:"center", fontWeight:"bold" }} variant="h6">{moment(register).add(12, 'M').format('DD-MM-YYYY')}</Typography>
            <Typography style={{ marginTop: 12, textAlign:"center", fontWeight:"bold" }} variant="h6">Serial Number</Typography>
            <Typography style={{ textAlign:"center", fontWeight:"bold" }} variant="body1">{serial}</Typography>
            
          </Grid>
        </ThemeProvider>
    )
    
  }
  
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please make sure all fields are filled in correctly."}</DialogTitle>
                <DialogContent>

                <DialogContentText id="alert-dialog-description">
                  {"Name: " + lastname + " " + name}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Phone Number: " + phone}
                </DialogContentText><DialogContentText id="alert-dialog-description">
                  {"Birthday: " + birthday}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Email: "+ email}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Gender: " + gender}
                </DialogContentText>

                </DialogContent>
                <DialogActions>
                <Button style={{ color: 'gray', fontWeight: 'bold'}} onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button style={{ fontWeight: 'bold'}} onClick={handleYes} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid style={{ padding: 20 }}>

              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Lucky Draw & Warranty</Typography>
              <Typography style={{textAlign:"center", fontWeight:"bold" }} variant="h6">Registration Form </Typography>
            <Grid style={{ maxWidth: 500, width: "100%", marginTop: 15 }}>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={isname ?false: true}
                      id="name"
                      
                      value={name}
                      onChange={handleNameChange}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                      helperText={islastname ? "":"Incorrect First Name."}
                    />
                    </form>
                </Grid>
                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={islastname ?false: true}
                      id="last_name"
                      variant="outlined"
                      value={lastname}
                      onChange={handleLastNameChange}
                      label="Last Name"
                      fullWidth
                      helperText={islastname ? "":"Incorrect Last Name."}
                    />
                    </form>
                </Grid>
              </Grid>

                  <Grid item xs={12}>
                      <MuiPhoneInput
                      autoFormat={false}
                      error={isphone ?false: true}
                        style={{ width: '100%', marginTop: 15 }}
                        defaultCountry='my'
                        regions={'asia'}
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                  <Grid container style={{ marginTop: 15 }} spacing={2}>
                    
                    <Grid item md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        style={{ width: '100%'}}
                       error={isbirthday ?false: true}
                              id="date"
                              label="Birthday"
                              type="date"
                              value={birthday}
                              
                              onChange={handleDateChange}
                            />
                            </form>
                    </Grid>
                    <Grid item  md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        error={isemail ?false: true}
                        id="email"
                        autoComplete='off'
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        label="Email Address"
                        type="email"
                        fullWidth
                        helperText={isemail ? "":"Incorrect email."}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                      </form>
                    </Grid>
                  </Grid>
                 
                  <Grid style={{ marginTop: 15 }}>
                    <FormControl component="fieldset">
                      <FormLabel style={{ fontSize: 14, color:"white" }} component="legend">Gender</FormLabel>
                      <RadioGroup style={{ display: "flex", flexDirection: "row"}} aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                        <FormControlLabel value="Female" control={<Radio color="primary" />} label={"Female"} />
                        <FormControlLabel value="Male" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Button onClick={hancleClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Submit</Button>
            </Grid>
            </Grid>
            </MuiPickersUtilsProvider>
        </div>
        </ThemeProvider>
      );
  

  
}