import React from 'react';
import Grid from '@material-ui/core/Grid';
import QrReader from 'react-qr-reader'

export default function QRScanner(props) {
  
  const handleScan = (data) => {
    if(data){
      window.location = 'https://authorize.sp2smalaysia.com/warrantly/'+data;
    }
  }

  const handleError = (err) =>{
    console.error(err)
  }

  return (
      <Grid>
        <QrReader
          delay={50}
          onError={handleError}
          onScan={handleScan}
          />
      </Grid>
    );
  

  
}