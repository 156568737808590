import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from "firebase";
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';
import DialogContentText from '@material-ui/core/DialogContentText';
import QRCode from "qrcode.react";
import JSZip from "jszip";
import FileSaver from 'file-saver';
import LinkIcon from '@material-ui/icons/Link';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import FolderIcon from '@material-ui/icons/Folder';
import Vapelist from './Vape_list';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'created', numeric: true, disablePadding: false, label: 'Created' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'control', numeric: true, disablePadding: false, label: '' },
];

const headMobileCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'control', numeric: true, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, matches } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all ' }}
          />
        </TableCell>
        {!matches ? headMobileCells.map((headCell) => (
          <TableCell
            style={{padding: 3}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )):headCells.map((headCell) => (
          <TableCell
            style={{padding: 3}}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onclickAddFolder, onclickDeleteAllFolder } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onclickDeleteAllFolder} >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add Folder">
          <IconButton aria-label="add-folder" onClick={onclickAddFolder}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onclickAddFolder: PropTypes.func.isRequired,
  onclickDeleteAllFolder:  PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('registered_date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(0);
  const [searchtxt, setSearchtxt] = React.useState("");
  const [data, setData] = React.useState([]);
  const [selectedinfo, setSelectedinfo] = React.useState(0);
  const [foldername, setFoldername] = React.useState("");
  const [selectedFolder, setSelectedFolder] = React.useState(null);
  const [vapes, setVapes] = React.useState([]);
  const [loaditem, setLoaditem] = React.useState(true);
  var zip = new JSZip();
  var count = 0;

  useEffect(() => {
    loadFolder()

  }, []);

  const loadFolder = () => {
    let vapeRef = firebase.database().ref('vape/');
    vapeRef.once('value', snapshot => {
     
      var vapes = [];
      snapshot.forEach(child => {

        var vapes_value = child.val();
        vapes.push(vapes_value);
        setVapes(vapes)
      })

      let vapeFolderRef = firebase.database().ref('vape_folder/');

      vapeFolderRef.on('value', snapshot => {
      
        var folder = [];
        snapshot.forEach(child => {

          

          var vapes_value = child.val();
          vapes_value.id = child.key;
          const isItemContained = vapes.map(item => item.folder).indexOf(vapes_value.id) !== -1;
          vapes_value.contain = isItemContained;
          vapes_value.name = child.val().name ? child.val().name : "";

          folder.push(vapes_value);
          
        })
        

        setData(folder)
        setRows(folder)
        setLoaditem(false)
      })

    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isContain = (id) => vapes.map(item => item.folder).indexOf(id) !== -1;

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadAllQR = () => {  
    count = 0;
    putintoZip();
  };

  const putintoZip = () => {
    const canvas = document.getElementById(rows[count].id);
    canvas.toBlob(function (blob) {
      zip.file(rows[count].id + ".png", blob);

      if(count < 10000-1){
        count++;
        console.log(count)
        putintoZip();
      }
      else
      {
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          FileSaver.saveAs(content, "Sp2sLuckyDrawQRcode"+ page+".zip");
        });
      }
      
    });
  }

  const search = event => {

    if(searchValue == 0){
      var filterData = data.filter(item => item.id ? item.id.includes(event.target.value): "");

      
      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    else if(searchValue == 1){

      var filterData = data.filter(item => item.name ? item.name.toLowerCase().includes(event.target.value.toLowerCase()): "");

      if(event.target.value != ""){
        setRows(filterData)
      }
      else{
        setRows(data)
      }
    }
    
    setSearchtxt(event.target.value)
    setPage(0)
  }

  const onChangeSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
    setRows(data)
  }

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [info, setInfo] = React.useState("");
  const [selectedID, setSelectedID] = React.useState("");
  const [editname, setEditname] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("");
  
  const onInfoClick = (info) => {
    setInfo(info);
    setOpen(true);
  }

  const handleClose = () => {
    loadFolder()
    setLoaditem(true)
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const enterFolder = (folder) => {
    setSelectedFolder(folder)
    setOpen(true);
  }

  const deleteFolder = (id) => {
    setSelectedID(id)
    setOpen3(true);
  }

  const editFolder = (selectedItem) => {
    setSelectedItem(selectedItem)
    setEditname(selectedItem.name)
    setOpen5(true)
  }

  const confirmDelete = () => {

    firebase.database().ref('vape_folder/' + selectedID).remove();
    setOpen3(false);
  }

  const onclickDeleteAllFolder = () => {
    setOpen4(true);
  }

  const confirmAllDelete = () => {

    selected.forEach(children =>{
      if(children.contain != true){
        firebase.database().ref('vape_folder/' + children.id).remove();
      }
    })

    setSelected([])
    setOpen4(false);
  }

  const onclickAddFolder = (info) => {
    setOpen2(true);
  }

  const confirmAddFolder = () => {
    firebase.database().ref('vape_folder').push({ 
      name: foldername == "" ? "default" : foldername,
      created: firebase.database.ServerValue.TIMESTAMP
    })
    setFoldername("")
    setOpen2(false);
  }

  const onChangeFolderName = (event) => {
    setFoldername(event.target.value)
  }

  const confirmEdit = () => {

    firebase.database().ref('vape_folder/'+ selectedItem.id).update({ name : editname == ""? "default" : editname})
    setOpen5(false)
  }

  

  const onChangeEdit = (event) => {
    setEditname(event.target.value)
  }

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  if (loaditem) {
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center',marginTop: 30  }}>
      <CircularProgress color="primary" /></div>;
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>
        <DialogContent>
        <TextField id="outlined-basic" label="Name" 
        onChange={onChangeEdit}
        value={editname}
        variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} color="primary">
            cancel
          </Button>
          <Button onClick={confirmEdit} color="primary" autoFocus>
            submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete these folders?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary">
            cancel
          </Button>
          <Button onClick={confirmAllDelete} color="primary" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this folder?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary">
            cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Folder"}</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <TextField id="outlined-basic" label="Folder name" variant="outlined" value={foldername} onChange={onChangeFolderName} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmAddFolder} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            {selectedFolder ? selectedFolder.name : ""}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid>
        <Vapelist folder={selectedFolder ? selectedFolder.id : null}/>
        </Grid>
        
      </Dialog>
      <Paper className={classes.paper}>
        <Grid style={{ display:'none', width:"100%", justifyContent:"flex-end", alignItems: "flex-end"}}>
          <Tooltip title="Download Vape List">
            <Button onClick={downloadAllQR} style={{ whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" startIcon={<GetAppIcon />} color="primary" disableElevation>
            Download Vape List
            </Button>
            </Tooltip>
            <Tooltip title="Download All QR code">
            <Button style={{ backgroundColor:"#061a26", whiteSpace:'nowrap', paddingLeft: 25, paddingRight: 25 }} variant="contained" startIcon={<GetAppIcon />} color="primary" disableElevation>
            Download All QR code
            </Button>
            </Tooltip>
        </Grid>
            
        <EnhancedTableToolbar numSelected={selected.length} onclickAddFolder={onclickAddFolder} onclickDeleteAllFolder={onclickDeleteAllFolder}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              matches={matches}
            />
            <TableCell padding={'none'}  colSpan={8}>
              
              <Grid container>
                <Grid item xs={12} md={10}>
                <FormControl style={{ marginTop:10 }}  fullWidth >
                    <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                    <Input onChange={(e) => search(e)}
                      value={searchtxt}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment style={{ marginLeft:20 }} position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}  md={2} style={{marginTop:10, paddingLeft: 15}}>
                  <FormControl style={{ width: "100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">Search by</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={searchValue}
                        label="Search by"
                        onChange={(e) => onChangeSelect(e)}
                      >
                        <MenuItem value={0}>Id</MenuItem>
                        <MenuItem value={1}>Name</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>
              </Grid>
                
            </TableCell>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  //const isItemContained = isContain(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      
                      <TableCell style={{padding: 3}} align="left">
                      {row.name}
                      </TableCell>
                      {!matches ? null : <TableCell style={{padding: 3}} align="left">{row.created ? new Intl.DateTimeFormat('en-US', {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(row.created):"-"}</TableCell>}
                      {!matches ? null : <TableCell style={{padding: 3}} align="left">
                      {row.id}
                      </TableCell>}
                      <TableCell style={{padding: 3}} align="left">
                        <Tooltip title="Enter Folder">
                          <IconButton onClick={() => enterFolder(row) }>
                              <FolderIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Name">
                          <IconButton onClick={() => editFolder(row) }>
                              <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Folder">
                          <IconButton disabled={row.contain} onClick={() => deleteFolder(row.id) }>
                              <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}