import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import MuiPhoneInput from 'material-ui-phone-number';
import Divider from '@material-ui/core/Divider';
import EmailValidator from 'email-validator';
import DropzoneProduct from "./DropzoneProduct";
import axios from "axios";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
  

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%",
    height: "100%",
    backgroundColor: "#1a1a1a",
    color: "white"
  }
});

var upload_count = 0;
var stopUpload = false;
var uploading = false;
var mediaLink = []

export default function MediaCard(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [serial, setSerial] = React.useState("");
  const [register, setRegister] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [name, setName] =  React.useState("");
  const [productId, setProductId] =  React.useState("");
  const [storeId, setStoreId] =  React.useState("");
  const [lastname, setLastname] =  React.useState("");
  const [phone, setPhone] =  React.useState("");
  const [email, setEmail] =  React.useState("");
  const [address, setAddress] =  React.useState("");
  const [gender, setGender] =  React.useState("Female");
  const [birthday, setBirthday] = React.useState("2021-01-01");
  const [pod, setPod] = React.useState("");
  const [otherpod, setOtherPod] = React.useState("");

        
  const [isname, setIsname] =  React.useState(true);
  const [isIc, setIsIc] =  React.useState(true);
  const [isaddress, setIsaddress] =  React.useState(true);
  const [islastname, setIslastname] =  React.useState(true);
  const [isphone, setIsphone] =  React.useState(true);
  const [isemail, setIsemail] =  React.useState(true);
  const [isgender, setIsgender] =  React.useState(true);
  const [isbirthday, setIsbirthday] = React.useState(true);
  const [isproductid, setIsproductid] = React.useState(true);
  const [isstoreid, setIsstoreid] = React.useState(true);
  const [isproductmedia, setIsproductmedia] = React.useState(true);
  const [ispod, setIspod] = React.useState(true);
  const [isotherpod, setIsotherpod] = React.useState(true);
  const [caseNumber, setCaseNumber] = React.useState(null);
  const [userKey, setUserKey] = React.useState(null);
  const [userRedeem, setUserRedeem] = React.useState(0);

  const [productMedia, setProductMedia] = React.useState([]);
  const [ic, setIc] =  React.useState("");


  const [luckynumber, setLuckynumber ]= React.useState("");

  const theme = createMuiTheme({
    palette: {
        primary: {
          light: '#99d700',
          main: '#99d700',
          dark: '#99d700',
        },
        secondary: {
          light: '#c90808',
          main: '#a60000',
          dark: '#930000',
        },
      type: 'dark',
    },
    
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  })

  const handleUploadSuccess = (metadata, filename) => {

    firebase.storage().ref("product_exchange_media")
    .child(metadata.name)
    .getDownloadURL().then((url) => {

        if(metadata.contentType === "video/mp4"){
            var media = new Audio(url);

            media.onloadedmetadata = function(){

                if(media.duration){

                    var newPostKey = firebase.database().ref().child("product_exchange_media").push().key;

                    var postData = {
                        id: newPostKey,
                        name: filename,
                        file_name: metadata.name,
                        url: url,
                        size: metadata.size,
                        created: firebase.database.ServerValue.TIMESTAMP,
                        type: metadata.contentType,
                        full_path: metadata.fullPath,
                        duration: media.duration
                    };
                    
                    mediaLink.push(postData)
                
                    var updates = {};
                    updates[newPostKey] = postData;
                    firebase.database().ref().child("product_exchange_media").update(updates).then(()=>{
                        stopUpload = false;
                        completedUpload();
                    })
                }
            };    
        
        }
        else
        {

            
            var newPostKey = firebase.database().ref().child("product_exchange_media").push().key;

            var postData = {
                id: newPostKey,
                name:filename,
                file_name: metadata.name,
                url: url,
                size: metadata.size,
                created: firebase.database.ServerValue.TIMESTAMP,
                type: metadata.contentType,
                full_path: metadata.fullPath,
                folder: props.folder ? props.folder : null
            };

            mediaLink.push(postData)

            var updates = {};
            updates[newPostKey] = postData;
           
            firebase.database().ref().child("product_exchange_media").update(updates).then(()=>{
                stopUpload = false;
                completedUpload();
            })
        }
    })
    
  }

  const handleSelectChange= (e) => {
    setPod(e.target.value);
  }

  const completedUpload = () =>{

    if(stopUpload === false){
        upload_count++;
        console.log(upload_count)
        uploadFiles();
    }
    else
    {
        uploading = false;
    }
  };

  const uploadFiles = () => {
    if(productMedia.length === upload_count){
        setLoading(false)
        setProductMedia([])
        setOpen(false);
        setRegister(true)
        
        firebase.database().ref('/product_exchange').push({
            registered_date: firebase.database.ServerValue.TIMESTAMP,
            name:name,
            phone: phone,
            email: email,
            gender: gender,
            last_name: lastname,
            birthday: birthday,
            address: address,
            product_id: productId,
            store_id: storeId,
            media: mediaLink,
            ic: ic
        }).then(()=>{
          mediaLink = []
        })
    
        setName("")
        setLastname("")
        setPhone("")
        setEmail("")
        setGender("Female")
        setBirthday("2021-01-01")
        setProductId("")
        setStoreId("")
        setAddress("")
    }else{
        var filename = productMedia[upload_count].name;
        var upload_task = firebase.storage().ref("product_exchange_media").child(new Date().getTime() +"_"+productMedia[upload_count].name).put(productMedia[upload_count]);
        upload_task.then((snapshot) => {
            handleUploadSuccess(snapshot.metadata, filename);
        }).catch((error)=>{
            console.log(error.message)
        })

    }
  }

  const padLeadingZeros = (num, size) => {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }


  const handleYes = () => {

    setOpen(false);
    setRegister(true)

    firebase.database().ref('/product_exchange_count').get().then((snapshot) => {
      if(snapshot.exists()){

        firebase.database().ref('/product_exchange_count').update({ count: snapshot.val().count +1 }).then(()=>{
           firebase.database().ref('/product_exchange').push({
            registered_date: firebase.database.ServerValue.TIMESTAMP,
            name:name,
            phone: phone,
            email: email,
            gender: gender,
            last_name: lastname,
            birthday: birthday,
            address: address,
            product_id: productId,
            store_id: storeId,
            brand_pod: pod,
            others_brand_pod: otherpod,
            case_number: snapshot.val().count,
            ic: ic
          })
        }) 

        setCaseNumber(snapshot.val().count)
      }
    })

    setName("")
    setLastname("")
    setPhone("")
    setEmail("")
    setGender("Female")
    setBirthday("2021-01-01")
    setProductId("")
    setStoreId("")
    setAddress("")
    setPod("")
    setOtherPod("") 
  };

  const hancleBackClick = () => {
    
    setRegister(false)
  };


  const handleClose = () => {
    setOpen(false);
  };

  const hancleClick = (event) => {

    setLoading(true)
    const result = EmailValidator.validate(email);
    var phone_result = false;
    var email_result = false;
    var name_result = false;
    var last_name_result = false;
    var gender_result = false;
    var address_result = false;
    var product_id_result = false;
    var store_id_result = false;
    var pod_result = false;
    var ic_result = false;

    if(ic.length > 11)
    {
      ic_result = true;
      setIsIc(true)
    }
    else
    {
      setIsIc(false)
    }

    if(pod !== "")
    {
      pod_result = true;
      setIspod(true)
    }
    else
    {
      setIspod(false)
    }

    var apiLink = "https://ck.fw-12365.com/ashx/SecurityQueryApi.ashx?Key=tc201805051108&FWCode=" + productId.replaceAll(/\s/g,'') + "&Lang=zh-en";

    if(address.length > 7)
    {
      address_result = true;
      setIsaddress(true)
    }
    else
    {
      setIsaddress(false)
    }

    if(name.length > 2)
    {
      name_result = true;
      setIsname(true)
    }
    else
    {
      setIsname(false)
    }

    if(lastname.length > 2)
    {
      last_name_result = true;
      setIslastname(true)
    }
    else
    {
      setIslastname(false)
    }

    if(gender != ""){
      gender_result = true;
      setIsgender(true)
    }
    else
    {
      setIsgender(false)
    }

    if(result == false){
      setIsemail(false)
    }
    else{
      setIsemail(true)
    }

    if(phone.length > 7){
      phone_result = true;
      setIsphone(true)
    }
    else{
      setIsphone(false)
    }


    if(storeId.replaceAll(/\s/g,'').toUpperCase().length > 5)
    {
      store_id_result = true;
      setIsstoreid(true)
    }

    else{
      store_id_result = false;
      setIsstoreid(false)
    }
    
    axios.get(apiLink).then((response) => {

      //if(apiLink){
      if(response.data.State != 0 && response.data.State > 0){
        
        let productRef = firebase.database().ref('product_exchange');

        productRef.once('value', snapshot => {
         
          var productExist = false;
          var emailExist = false;
          var phoneRepeat = false;
          var _same_ic = false;
          var total_ic = [];
          var total_phone = [];
          var total_email = [];

          snapshot.forEach(child => {

            var product_value = child.val();
            product_value.id = child.key
            
            if(!product_value.folder)
            {
              if(product_value.product_id === productId.replaceAll(/\s/g,'')){
                productExist = true;
              }
  
              if(product_value.email === email.replaceAll(/\s/g,'')){
                //emailExist = true
                total_email.push(product_value.email)
              }
  
              if(product_value.phone === phone){
                //phoneRepeat = true;
                total_phone.push(product_value.phone)
              }
  
              if(product_value.ic === ic){
                //_same_ic = true;
                total_ic.push(product_value.ic);
              }
            }

          })

          if(total_ic.length > 0){
            _same_ic = true;
          }

          if(total_email.length > 0){
            emailExist = true;
          }

          if(total_phone.length > 0){
            phoneRepeat = true;
          }
         
          if(productExist !== true){
            product_id_result = true;
            setIsproductid(true)
          }
          else{
            setIsproductid(false)
          }

          if(phoneRepeat !== true){
            phone_result = true;
            setIsphone(true)
          }
          else{
            phone_result = false;
            setIsphone(false)
          }

          if(emailExist !== true){
            email_result = true;
            setIsemail(true)
          }
          else{
            setIsemail(false)
          }

          if(_same_ic === true){
            setIsIc(false)
          }

          

          firebase.database().ref('store_user').once('value', usersnapshot => {

            //console.log("dddddd")
            var userExist = false;
            var _userKey = null;
            var _userRedeem = null;
            usersnapshot.forEach(user_child => {
      
              var user_value = user_child.val();
              user_value.id = user_child.key
              
              let storeID = user_value.store_id;
              
              //console.log(storeID.toString().toUpperCase())
              if(storeID.toString().toUpperCase() === storeId.replaceAll(/\s/g,'').toUpperCase()){
                userExist = true
                _userKey = user_value.id;
                _userRedeem = user_value.redeem_time
              }
            })
      
            if(userExist === true){
              store_id_result = true;
              setIsstoreid(true)
            }
            else
            {
              store_id_result = false;
              setIsstoreid(false)
            }
      

            if(result == true && 
              _same_ic === false && 
              ic_result == true &&
              email_result == true &&
              phone_result == true && 
              name_result == true && 
              gender_result == true && 
              last_name_result == true && 
              product_id_result  == true && 
              store_id_result  == true && 
              address_result == true &&
              pod_result == true 
              ){
        
              setOpen(true);
            }

            setLoading(false)
      
          })
          
          

        })
       
      }
      else
      {
        
        setIsproductid(false)
        setLoading(false)
      }

      

      

    });

    

  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleProductIdChange = (event) => {
    setProductId(event.target.value)
  }

  const handleOtherPodChange = (event) => {
    setOtherPod(event.target.value)
  }

  const handleStoreIdChange = (event) => {
    setStoreId(event.target.value)
  }

  const handleLastNameChange = (event) => {
    setLastname(event.target.value)
  }

  const handleAddressChange = (event) => {
    setAddress(event.target.value)
  }

  const handlePhoneChange = (value) => {
    console.log(value)
    setPhone(value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  

  const handleDateChange = (event) => {
    setBirthday(event.target.value);
  };

  const handleSetProduct = (products) => {
    console.log(products);
    setProductMedia(products);
  };

  if(loading){
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
  }

  if(register){

    return(
        <ThemeProvider theme={theme}>
          <Grid style={{ padding: 20, display:'flex', justifyContent: "center" }}>
            <Grid style={{ backgroundColor: "#1a1a1a", color: "white", height: "100%", maxWidth: 500 }}>
              <Grid style={{ display:'flex', justifyContent: "center" }}>
                <img style={{ width: 150, marginBottom: 15 }} src='/sp2smalaysialogo_low2.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h4">Thank you!</Typography>
              <Typography style={{ marginTop: 15, textAlign:"center" }} variant="h6">You submission has been received!</Typography>
              <Typography style={{ marginTop: 15, textAlign:"center" }} variant="body1">Case Number</Typography>
              <Typography style={{ marginTop: 5, textAlign:"center" }} variant="h3">{caseNumber ? "CN" + padLeadingZeros(caseNumber, 6): ""}</Typography>
              <Button onClick={hancleBackClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Back</Button>
            </Grid>
          </Grid>
        </ThemeProvider>
    )
    
  }
  
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please make sure all fields are filled in correctly."}</DialogTitle>
                <DialogContent>

                <DialogContentText id="alert-dialog-description">
                  {"Brand of POD: " + pod}
                </DialogContentText>
                {pod === "others" ? <DialogContentText id="alert-dialog-description">
                  {"Others Brand of POD: " + otherpod}
                </DialogContentText> : null}
                <DialogContentText id="alert-dialog-description">
                  {"Product ID: " + productId}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Store ID: " + storeId}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Name: " + lastname + " " + name}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Phone Number: " + phone}
                </DialogContentText><DialogContentText id="alert-dialog-description">
                  {"Birthday: " + birthday}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Email: "+ email}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Address: "+ address}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Gender: " + gender}
                </DialogContentText>

                <DialogContentText id="alert-dialog-description">
                  {"IC: " + ic}
                </DialogContentText>

                </DialogContent>
                <DialogActions>
                <Button style={{ color: 'gray', fontWeight: 'bold'}} onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button style={{ fontWeight: 'bold'}} onClick={handleYes} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid style={{ padding: 20 }}>

              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ width: 150, marginBottom: 15 }} src='/sp2smalaysialogo_low2.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Sptime Product Exchange</Typography>
            <Grid style={{ maxWidth: 500, width: "100%", marginTop: 15 }}>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid md={12} item xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%", marginTop: 10}}>
                    <InputLabel id="demo-simple-select-outlined-label">Closed POD</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={pod}
                      error={ispod ?false: true}
                      onChange={handleSelectChange}
                      label="Closed POD"
                    >
                      <MenuItem value={"relx_closed"}>Relx</MenuItem>
                      <MenuItem value={"snowplus_closed"}>Snowplus</MenuItem>
                      <MenuItem value={"dd_closed"}>DD</MenuItem>
                      <MenuItem value={"nanostix_closed"}>Nanostix</MenuItem>
                      <MenuItem value={"wuuz_closed"}>Wuuz</MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {pod === "others" ? <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isotherpod ?false: true}
                          id="Others Brand"
                          value={otherpod}
                          onChange={handleOtherPodChange}
                          label="Please insert others brand POD"
                          variant="outlined"
                          fullWidth
                          inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                        />
                    </form>
                </Grid>: null}
                <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isproductid ?false: true}
                          id="Product ID"
                          value={productId}
                          onChange={handleProductIdChange}
                          label="Product ID"
                          variant="outlined"
                          fullWidth
                          inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                        />
                    </form>
                </Grid>
                <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isstoreid ?false: true}
                          id="Store ID"
                          value={storeId}
                          onChange={handleStoreIdChange}
                          label="Store ID"
                          variant="outlined"
                          fullWidth
                          inputProps={{ style: { backgroundColor: "#1a1a1a", textTransform: 'uppercase'} }}
                        />
                    </form>
                </Grid>
                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={isname ?false: true}
                      id="name"
                      
                      value={name}
                      onChange={handleNameChange}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                      helperText={islastname ? "":"Incorrect First Name."}
                    />
                    </form>
                </Grid>
                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={islastname ?false: true}
                      id="last_name"
                      variant="outlined"
                      value={lastname}
                      onChange={handleLastNameChange}
                      label="Last Name"
                      fullWidth
                      helperText={islastname ? "":"Incorrect Last Name."}
                    />
                    </form>
                </Grid>
                <Grid item xs={12}>
                <Typography style={{ marginTop: 15, fontSize: 12, opacity:.7 }} variant="h6">Identity Card</Typography>
                <NumberFormat 
                value={ic}
                  style={{ marginTop: 10, fontSize: 24, width: "100%", backgroundColor: "#1a1a1a", color: "white", padding: 10 }} 
                  format="###### ## ####" mask="_" 
                  onValueChange={(e)=>{ setIc(e.value) }}
                  />
                  {!isIc? <Typography style={{ marginTop: 15, fontSize: 12, color: "red" }} variant="h6">Invalid / Registered Identity Card</Typography> : null}
                </Grid>
                </Grid>

                  <Grid item xs={12}>
                      <MuiPhoneInput
                      autoFormat={false}
                      error={isphone ?false: true}
                        style={{ width: '100%', marginTop: 15 }}
                        defaultCountry='my'
                        regions={'asia'}
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                    {isphone ? null : <Typography style={{ padding: 15, color: "red" }} variant="caption">Phone Number invalid or registered</Typography>}
                  <Grid container style={{ marginTop: 15 }} spacing={2}>
                    
                    <Grid item md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        style={{ width: '100%'}}
                       error={isbirthday ?false: true}
                              id="date"
                              label="Birthday"
                              type="date"
                              value={birthday}
                              
                              onChange={handleDateChange}
                            />
                            </form>
                    </Grid>
                    <Grid item  md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        error={isemail ?false: true}
                        id="email"
                        autoComplete='off'
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        label="Email Address"
                        type="email"
                        fullWidth
                        helperText={isemail ? "":"Incorrect email."}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                      </form>
                    </Grid>
                    <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isaddress ?false: true}
                          id="address"
                          variant="outlined"
                          value={address}
                          onChange={handleAddressChange}
                          label="Address"
                          fullWidth
                          multiline
                          maxRows={4}
                          helperText={isaddress ? "":"Fill up address"}
                        />
                        </form>
                    </Grid>
                  </Grid>
                 
                  <Grid style={{ marginTop: 15 }}>
                    <FormControl component="fieldset">
                      <FormLabel style={{ fontSize: 14, color:"white" }} component="legend">Gender</FormLabel>
                      <RadioGroup style={{ display: "flex", flexDirection: "row"}} aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                        <FormControlLabel value="Female" control={<Radio color="primary" />} label={"Female"} />
                        <FormControlLabel value="Male" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Button onClick={hancleClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Submit</Button>
            </Grid>
            </Grid>
            </MuiPickersUtilsProvider>
        </div>
        </ThemeProvider>
      );
  

  
}