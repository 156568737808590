import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState(false);

    return (
        <div className={classes.root}>
            {loaded ? null : <Grid style={{ padding: 30, display: 'flex',  justifyContent:'center', alignItems:'center'}} >
                    <CircularProgress color="primary" />
                </Grid> }
            <Grid style={{ maxWidth: 800 }}>
                <Grid style={{ width: "100%"}}>
                    <img
                    onLoad={()=> setLoaded(true)}
                    width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/sp2s-luckydraw.appspot.com/o/Voucher%2FVoucher_invalid.jpg?alt=media&token=f6d3ff1a-3494-4e4c-8374-9a618fca5bf8"
                    alt="SP2s InvalidVoucher"
                    />
                </Grid>
            </Grid>
        </div>
      );

}