import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import MuiPhoneInput from 'material-ui-phone-number';
import Divider from '@material-ui/core/Divider';
import EmailValidator from 'email-validator';
import QrReader from 'react-qr-reader'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import axios from "axios";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
  

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center",
    width:"100%",
    height: "100%",
    backgroundColor: "#1a1a1a",
    color: "white"
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [serial, setSerial] = React.useState("");
  const [register, setRegister] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [name, setName] =  React.useState("");
  const [box, setBox] =  React.useState("");
  const [lastname, setLastname] =  React.useState("");
  const [phone, setPhone] =  React.useState("");
  const [email, setEmail] =  React.useState("");
  const [address, setAddress] =  React.useState("");
  const [ic, setIc] =  React.useState("");
  const [gender, setGender] =  React.useState("Female");
  const [gen, setGen] =  React.useState("gen1");
  const [birthday, setBirthday] = React.useState("2021-01-01");
        
  const [isname, setIsname] =  React.useState(true);
  const [isIc, setIsIc] =  React.useState(true);
  const [isbox, setIsbox] =  React.useState(true);
  const [isaddress, setIsaddress] =  React.useState(true);
  const [islastname, setIslastname] =  React.useState(true);
  const [isphone, setIsphone] =  React.useState(true);
  const [isemail, setIsemail] =  React.useState(true);
  const [isgender, setIsgender] =  React.useState(true);
  const [isbirthday, setIsbirthday] = React.useState(true);

  const [open2, setOpen2] = React.useState(false);
  const [selectedCamera, setSelectedCamera] = React.useState("environment");

  const [luckynumber, setLuckynumber ]= React.useState("");

  const theme = createMuiTheme({
    palette: {
        primary: {
          light: '#99d700',
          main: '#99d700',
          dark: '#99d700',
        },
        secondary: {
          light: '#c90808',
          main: '#a60000',
          dark: '#930000',
        },
      type: 'dark',
    },
    
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  })

  const handleYes = () => {
    setOpen(false);
    setRegister(true)
    firebase.database().ref('/customer_form').push({
        registered_date: firebase.database.ServerValue.TIMESTAMP,
        name:name,
        phone: phone,
        email: email,
        gender: gender,
        last_name: lastname,
        birthday: birthday,
        address: address,
        serial_number: box,
        ic: ic,
        gen: gen
    });

    setName("")
    setLastname("")
    setPhone("")
    setEmail("")
    setGender("Female")
    setBirthday("2021-01-01")
    setBox("")
  };

  const hancleBackClick = () => {
    
    setRegister(false)
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const hancleClick = (event) => {

    const result = EmailValidator.validate(email);
    var phone_result = false;
    var name_result = false;
    var last_name_result = false;
    var gender_result = false;
    var address_result = false;
    var ic_result = false;

    if(ic.length > 11)
    {
      ic_result = true;
      setIsIc(true)
    }
    else
    {
      setIsIc(false)
    }

    if(phone.length > 7)
    {
      phone_result = true;
      setIsphone(true)
    }
    else
    {
      setIsphone(false)
    }

    if(address.length > 7)
    {
      address_result = true;
      setIsaddress(true)
    }
    else
    {
      setIsaddress(false)
    }

    if(name.length > 2)
    {
      name_result = true;
      setIsname(true)
    }
    else
    {
      setIsname(false)
    }

    if(lastname.length > 2)
    {
      last_name_result = true;
      setIslastname(true)
    }
    else
    {
      setIslastname(false)
    }

    if(gender != ""){
      gender_result = true;
      setIsgender(true)
    }
    else
    {
      setIsgender(false)
    }

    if(result == false){
      setIsemail(false)
    }
    else{
      setIsemail(true)
    }

    firebase.database().ref('customer_form').once('value', usersnapshot => {
      if(usersnapshot.exists()){

        var _same_phone = false;
        var _same_email = false;
        var _same_box = false;
        var _same_ic = false;

        usersnapshot.forEach(child => {

         
            var item_val = child.val();
          if(!item_val.folder){
            if(item_val.phone === phone.toString().replaceAll(/\s/g,'') && item_val.folder !== "-N5rlKqeZXUQj4VFZB8Y"){
              _same_phone = true;
            }
  
            if(item_val.email === email.toString().replaceAll(/\s/g,'') && item_val.folder !== "-N5rlKqeZXUQj4VFZB8Y"){
              _same_email = true;
            }
  
            if(item_val.serial_number === box.toString().replaceAll(/\s/g,'') && item_val.folder !== "-N5rlKqeZXUQj4VFZB8Y"){
              _same_box = true;
            }
  
            if(item_val.ic === ic && item_val.folder !== "-N5rlKqeZXUQj4VFZB8Y"){
              _same_ic = true;
            }
          }

        })

        if(_same_phone === true){
          setIsphone(false)
        }

        if(_same_email === true){
          setIsemail(false)
        }

        if(_same_ic === true){
          setIsIc(false)
        }

        if(_same_box === true || box.toString().replaceAll(/\s/g,'').length < 10 ){
          setIsbox(false)
        }

        var apiLink = "https://ck.fw-12365.com/ashx/SecurityQueryApi.ashx?Key=tc201805051108&FWCode=" + box.toString().replaceAll(/\s/g,'') + "&Lang=zh-en";

        axios.get(apiLink).then((response) => {
          if(response.data.State == 1 || response.data.State == 2){
            
            if(_same_ic === false && _same_phone === false && _same_email === false && _same_box === false && box.toString().replaceAll(/\s/g,'').length > 9){
              if(ic_result == true && result == true && phone_result == true && name_result == true && gender_result == true && last_name_result == true){
                setOpen(true);
              }
            }

          }
          else{
            setIsbox(false)
          }

        });
        
       
      }
    })

    

  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleBoxChange = (event) => {
    setBox(event.target.value)
  }

  const handleLastNameChange = (event) => {
    setLastname(event.target.value)
  }

  const handleAddressChange = (event) => {
    setAddress(event.target.value)
  }

  const handlePhoneChange = (value) => {
    console.log(value)
    setPhone(value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }
  
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleGenChange = (event) => {
    setGen(event.target.value);
  };

  const handleDateChange = (event) => {
    setBirthday(event.target.value);
  };

  const scanQR = () => {
    
    setOpen2(true)
  }

  const handleChangeCamera = (e) => {
    setSelectedCamera(e.target.value)
  }

  const handleScan = (data) => {
    if(data){
        if(data.startsWith("http://sbr.fangcuanhuoxitong.com/Home/Index/"))
        {
            const resultArray = data.split("/");
            setBox(resultArray[5]);
            setOpen2(false)
        }
    }
  }

  const handleError = (err) =>{
      console.error(err)
  }

  if(loading){
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
  }

  if(register){

    return(
        <ThemeProvider theme={theme}>
          <Grid style={{ padding: 20, display:'flex', justifyContent: "center" }}>
            <Grid style={{ backgroundColor: "#1a1a1a", color: "white", height: "100%", maxWidth: 500 }}>
              <Grid style={{ display:'flex', justifyContent: "center" }}>
                <img style={{ height: 80, marginBottom: 15 }} src='/sp2smalaysialogo_low.png'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h4">Thank you!</Typography>
              <Typography style={{ marginTop: 15, textAlign:"center" }} variant="h6">You submission has been received!</Typography>
              <Button onClick={hancleBackClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Back</Button>
            </Grid>
          </Grid>
        </ThemeProvider>
    )
    
  }
  
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please make sure all fields are filled in correctly."}</DialogTitle>
                <DialogContent>

                <DialogContentText id="alert-dialog-description">
                  {"Serial Number: " + box}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Name: " + lastname + " " + name}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Identity Card: " + ic}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Phone Number: " + phone}
                </DialogContentText><DialogContentText id="alert-dialog-description">
                  {"Birthday: " + birthday}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Email: "+ email}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Address: "+ address}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {"Gender: " + gender}
                </DialogContentText>

                </DialogContent>
                <DialogActions>
                <Button style={{ color: 'gray', fontWeight: 'bold'}} onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button style={{ fontWeight: 'bold'}} onClick={handleYes} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                fullScreen
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Scan the QR code"}</DialogTitle>
                <DialogContent>

                  <Grid style={{ width: "100%", heigth: 300, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                      <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedCamera}
                                  onChange={handleChangeCamera}
                              >
                              <MenuItem value={"environment"}>Back Camera</MenuItem>
                              <MenuItem value={"user"}>Front Camera</MenuItem>
                              </Select>
                      <Grid style={{ width: "100%" }}>
                      
                      
                      <QrReader
                          delay={50}
                          onError={handleError}
                          onScan={handleScan}
                      />

                      </Grid>

                      
                  </Grid>
                </DialogContent>
                <DialogActions>
                <Button style={{ color: 'gray', fontWeight: 'bold'}} onClick={handleClose2} color="primary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid>


              <Grid style={{ display:'flex', justifyContent: "center" }} >
                <img style={{ width: "100%", marginBottom: 15, maxWidth: 700 }} src='/ads_banner.jpg'/>
              </Grid>
              <Divider style={{ opacity:.3 }}/>
              <Typography style={{ marginTop: 15, textAlign:"center", fontWeight:"bold" }} variant="h6">Sp2s QR Code Giveaway</Typography>
              <Grid style={{ padding: 20}}>
              <Grid style={{ width: "100%", marginTop: 15 }}>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid md={12} item xs={12}>
                  <Grid container>
                    <Grid item xs={2}>
                        

                        <IconButton onClick={scanQR} color="primary" aria-label="scan qr" component="span">
                            <PhotoCamera />
                        </IconButton>
                    </Grid>
                    <Grid item  xs={10}>
                      <form noValidate autoComplete="off">
                      <TextField
                          error={isbox ?false: true}
                          id="serial number"
                          
                          value={box}
                          onChange={handleBoxChange}
                          label="Product Serial Number"
                          variant="outlined"
                          type={"number"}
                          fullWidth
                          inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                          helperText={isbox ? "":"Invalid Serial Number / Product already registered"}
                        />
                        </form>
                    </Grid>
                  </Grid>

                  <Grid style={{ marginTop: 15 }}>
                    <FormControl component="fieldset">
                      <FormLabel style={{ fontSize: 14, color:"white" }} component="legend">POD Generation</FormLabel>
                      <RadioGroup style={{ display: "flex", flexDirection: "row"}} aria-label="gen" name="gen" value={gen} onChange={handleGenChange}>
                        <FormControlLabel value="gen1" control={<Radio color="primary" />} label={"Generation 1"} />
                        <FormControlLabel value="gen2" control={<Radio color="primary" />} label="Generation 2" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                

                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={isname ?false: true}
                      id="name"
                      
                      value={name}
                      onChange={handleNameChange}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      inputProps={{ style: { backgroundColor: "#1a1a1a"} }}
                      helperText={islastname ? "":"Incorrect First Name."}
                    />
                    </form>
                </Grid>
                <Grid md={6} item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                      error={islastname ?false: true}
                      id="last_name"
                      variant="outlined"
                      value={lastname}
                      onChange={handleLastNameChange}
                      label="Last Name"
                      fullWidth
                      helperText={islastname ? "":"Incorrect Last Name."}
                    />
                    </form>
                </Grid>
              </Grid>

              <Grid item xs={12}>
              <Typography style={{ marginTop: 15, fontSize: 12, opacity:.7 }} variant="h6">Identity Card</Typography>
              <NumberFormat 
                style={{ marginTop: 10, fontSize: 24, width: "100%", backgroundColor: "#1a1a1a", color: "white", padding: 10 }} 
                format="###### ## ####" mask="_" 
                onValueChange={(e)=>{ setIc(e.value) }}
                />
                {!isIc? <Typography style={{ marginTop: 15, fontSize: 12, color: "red" }} variant="h6">Invalid / Registered Identity Card</Typography> : null}
              </Grid>

                  <Grid item xs={12}>
                      <MuiPhoneInput
                      autoFormat={false}
                      error={isphone ?false: true}
                        style={{ width: '100%', marginTop: 15 }}
                        defaultCountry='my'
                        regions={'asia'}
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                  <Grid container style={{ marginTop: 15 }} spacing={2}>
                    
                    <Grid item md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        style={{ width: '100%'}}
                       error={isbirthday ?false: true}
                              id="date"
                              label="Birthday"
                              type="date"
                              value={birthday}
                              
                              onChange={handleDateChange}
                            />
                            </form>
                    </Grid>
                    <Grid item  md={6} xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                        error={isemail ?false: true}
                        id="email"
                        autoComplete='off'
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        label="Email Address"
                        type="email"
                        fullWidth
                        helperText={isemail ? "":"Incorrect email."}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                      </form>
                    </Grid>
                    <Grid md={12} item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField
                          error={isaddress ?false: true}
                          id="address"
                          variant="outlined"
                          value={address}
                          onChange={handleAddressChange}
                          label="Address"
                          fullWidth
                          multiline
                          maxRows={4}
                          helperText={isaddress ? "":"Fill up address"}
                        />
                        </form>
                    </Grid>
                  </Grid>

                  
                 
                  <Grid style={{ marginTop: 15 }}>
                    <FormControl component="fieldset">
                      <FormLabel style={{ fontSize: 14, color:"white" }} component="legend">Gender</FormLabel>
                      <RadioGroup style={{ display: "flex", flexDirection: "row"}} aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                        <FormControlLabel value="Female" control={<Radio color="primary" />} label={"Female"} />
                        <FormControlLabel value="Male" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Button onClick={hancleClick} style={{ fontWeight: 'bold', fontSize: 18, marginTop: 30, width: "100%" }} variant="contained" disableElevation color="primary">Submit</Button>
            </Grid>
              </Grid>
            
            </Grid>
            </MuiPickersUtilsProvider>
        </div>
        </ThemeProvider>
      );
  

  
}