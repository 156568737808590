import firebase from "firebase";

//SP2s Lucky Draw
const app = firebase.initializeApp({
    apiKey: "AIzaSyA5G-j03WYV-1KAo-LCUfSIWl0OsJutK4E",
    authDomain: "sp2s-luckydraw.firebaseapp.com",
    databaseURL: "https://sp2s-luckydraw-default-rtdb.firebaseio.com",
    projectId: "sp2s-luckydraw",
    storageBucket: "sp2s-luckydraw.appspot.com",
    messagingSenderId: "125942090464",
    appId: "1:125942090464:web:cf643cde8360ec1cc9de85"
});

export default app;